import React from "react";
import { PropTypes as T } from "prop-types";

import NavGlobalMenu from "./NavGlobalMenu";

import { environment } from "../config";

export default class PageHeader extends React.PureComponent {
  render() {
    return (
      <header className="page__header" role="banner">
        <div className="page__headline">
          {(this.props.hamburgerOnClick && (
            <h1 className="page__hamburger">
              <a
                href="#"
                onClick={this.props.hamburgerOnClick}
                title="Show Parameters"
              >
                <span>Show Parameters </span>
              </a>
            </h1>
          )) ||
            ""}
        </div>
        <nav className="page__prime-nav nav" role="navigation">
          <NavGlobalMenu forHeader />
        </nav>
      </header>
    );
  }
}

if (environment !== "production") {
  PageHeader.propTypes = {
    location: T.object,
    hamburgerOnClick: T.func,
  };
}
