import { combineReducers } from "redux";
import { baseAPIReducer } from "./utils";

//
// SMOOTHING Reducer
//
const smoothingReducerInitialState = {
  fetched: false,
  fetching: false,
  data: {},
  error: null,
};
function smoothingReducer(state = smoothingReducerInitialState, action) {
  return baseAPIReducer(state, action, "SMOOTHING");
}

//
// ALTITUDE Reducer
//
const altitudeReducerInitialState = {
  fetched: false,
  fetching: false,
  data: {},
  error: null,
};
function altitudeReducer(state = altitudeReducerInitialState, action) {
  return baseAPIReducer(state, action, "ALTITUDE");
}

// SOLARPROFILE Reducer
//
const solarProfileReducerInitialState = {
  fetched: false,
  fetching: false,
  data: {},
  error: null,
};
function solarProfileReducer(state = solarProfileReducerInitialState, action) {
  return baseAPIReducer(state, action, "SOLARPROFILE");
}

//
//  ABOUT PAGE Reducer
//
const aboutPageReducerInitialState = {
  // fetching: false,
  // fetched: false,
  // error: null,
  // data: []
};
function aboutPageReducer(state = aboutPageReducerInitialState, action) {
  return baseAPIReducer(state, action, "ABOUT_PAGE");
}

export default combineReducers({
  smoothing: smoothingReducer,
  altitude: altitudeReducer,
  solarProfile: solarProfileReducer,
  individualAboutPages: aboutPageReducer,
});
