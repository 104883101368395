import { Component } from "react";

import App from "./App";
import NavAboutMenu from "../components/NavAboutMenu";

class Methodology extends Component {
  render() {
    return (
      <App pageTitle="Methodology">
        <article className="inpage inpage--about">
          <header className="inpage__header">
            <div className="inpage__subheader">
              <div className="inpage__headline">
                <h1 className="inpage__title">Methodology</h1>
              </div>
            </div>
          </header>
          <NavAboutMenu />
          <div className="inpage__body">
            <div className="prose">
              <h2>Methodology</h2>

              <p>
                This solar PV plus storage sizing tool aims to estimate the
                least-cost optimal size (MW/MWh) of a hybrid solar plus storage
                plant that meets the generation requirements for two
                applications: <strong>solar energy output smoothing</strong> and{" "}
                <strong>
                  shifting to deliver a user-defined demand profile
                </strong>
                . This solar PV plus storage sizing does not replace the
                comprehensive project sizing and design of hybrid solar plus
                storage projects.
              </p>
              <p>
                Using a linear programing solver, the application minimizes the
                annualized investment costs for the hybrid solar plus storage
                system, including the unserved load, by optimally dimensioning
                the solar PV and BESS. The linear program is subject to a number
                of constraints modeling the physical behavior of the system,
                including the efficiencies of the different elements.
              </p>

              <img
                src={
                  new URL(
                    "/app/assets/graphics/content/methodology.png",
                    import.meta.url
                  )
                }
                className="center"
              />

              <p>
                For the <em>smoothing</em> application, the user provides the
                smoothing time step. This value reflects the number of hours
                during which the hybrid solar PV plus storage system will
                provide a constant output. For example, if the smoothing time
                step is 2 hours then the solar PV plus storage system’s output
                will be a stepwise function with a 2-hour step.
              </p>
              <p>
                For the <em>shifting</em> application, the user provides the
                hourly demand profile and the hourly value of non-served energy
                (peak demand hours may have a higher value). The hybrid solar PV
                plus BESS system is optimized considering the demand and the
                value of unserved energy.
              </p>
              <p>The solution is a minimization of the objective function:</p>
              <p>
                <em>
                  Total Cost = Annual Solar Plant Cost + Annual BESS Energy Cost
                  + Annual BESS Power Cost + Unserved Energy Cost
                </em>
              </p>
              <p>
                The solution is constrained by a set of linear constraints that
                describe the bounds of the solution over time steps
                corresponding to the solar profile.
              </p>
              <p>For each time step:</p>
              <ul>
                <li>
                  <p>
                    0 &lt;= BESS Input &lt;= Solar Generation, BESS Power
                    Capacity
                  </p>
                </li>
                <li>
                  <p>0 &lt;= BESS Output &lt;= BESS Power Capacity</p>
                </li>
                <li>
                  <p>
                    Minimum State of Charge &lt;= BESS State of Charge &lt;=
                    BESS Energy Capacity
                  </p>
                </li>
                <li>
                  <p>
                    BESS State of Charge[n+1] = BESS State of Charge[n] + BESS
                    Input - BESS Output * Round Trip Efficiency
                  </p>
                </li>
                <li>
                  <p>
                    Solar Generation - BESS Input + (BESS Output * Round Trip
                    Efficiency) + Unserved Energy = Demand
                  </p>
                </li>
              </ul>
              <p>
                The output includes <span className="underline"> </span> the
                following time series across the year:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Load Served from Solar:</strong> Generation output
                    of the solar PV component
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Load Served from BESS:</strong> Output served from
                    the Battery component
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Solar Output:</strong> solar resource output data
                    from the Global Solar Atlas
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Served Energy:</strong> The output of the PV + BESS
                    plant
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    <strong>BESS discharge per hour (in MW):</strong>{" "}
                    Discharging of BESS for each time period
                  </p>
                </li>
                <li>
                  <p>
                    <strong>BESS charge per hour (in MW):</strong> Charging of
                    BESS for each time period
                  </p>
                </li>
                <li>
                  <p>
                    <strong>BESS Stored Energy (in MW):</strong> State of charge
                    of BESS for each time period
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Unserved energy (in MW):</strong> Energy that must
                    be served by other means
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </article>
      </App>
    );
  }
}

export default Methodology;
