import { Component } from "react";

import App from "./App";
import NavAboutMenu from "../components/NavAboutMenu";

class Inputs extends Component {
  render() {
    return (
      <App pageTitle="Inputs and Outputs">
        <article className="inpage inpage--about">
          <header className="inpage__header">
            <div className="inpage__subheader">
              <div className="inpage__headline">
                <h1 className="inpage__title">Inputs and Outputs</h1>
              </div>
            </div>
          </header>
          <NavAboutMenu />
          <div className="inpage__body">
            <div className="prose">
              <h2>Data Inputs</h2>

              <h3>Solar Parameters</h3>

              <ul>
                <li>
                  <p>Solar Profile</p>

                  <ul>
                    <li>
                      <p>
                        According to the location chosen by the user, the hourly
                        solar power output (in W/kWp) for 24 hourly values per
                        day is being obtained from the Global Solar Atlas. The
                        typical values should be between 0 and 1000. The
                        retrieved profiles from the Global Solar Atlas are the
                        average hourly profiles for one day per month, retrieved
                        from the Global Solar Atlas. The values assume a
                        ground-mounted large-scale system, with optimum tilt.
                      </p>
                    </li>
                    <li>
                      <p>
                        For uploaded profiles using a CSV file, the solar data
                        (hourly solar output in W/kWp) should be one CSV column,
                        with a multiple of 24 rows.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Solar Cost</p>
                  <ul>
                    <li>
                      <p>
                        The price of the solar portion of the plant, including
                        inverters and other supporting infrastructure.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Solar Asset Life</p>
                  <ul>
                    <li>
                      <p>
                        The economic lifetime of the solar portion of the plant,
                        which is generally around 25-30 years, considering the
                        energy production reduction over time.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Nominal Solar PV Size</p>
                  <ul>
                    <li>
                      <p>
                        Used for Smoothing solutions. The desired nominal size
                        of the PV plant inserted by the user. This is a specific
                        requirement for the solver -- it will return a solution
                        with the exact sizing of the battery storage for the
                        solar plant.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Solar Capacity Limit</p>
                  <ul>
                    <li>
                      <p>
                        Used for the Shifting solutions. This is the maximum
                        size of the PV plant considered by the solver for the
                        optimization process. The plant will be optimally sized
                        up to this value to serve the load described by the
                        demand profile which is inserted by the user.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>

              <h3>Smoothing Parameters</h3>

              <ul>
                <li>
                  Smoothing Period
                  <ul>
                    <li>
                      The requested time period in hours for the total system
                      output to be flattened to the average solar input over
                      that time.
                    </li>
                  </ul>
                </li>
              </ul>

              <h3>Shifting Parameters</h3>
              <ul>
                <li>
                  <p>Peak Demand</p>
                  <ul>
                    <li>
                      <p>
                        The power level corresponding to a factor of 1.0 on the
                        Demand Profile (MW).
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Demand Profile</p>
                  <ul>
                    <li>
                      <p>
                        The relative demand for power from the system over the
                        course of a day. This expects hourly values in the range
                        of [0, 1]. The peak value of 1.0 corresponds to the
                        value given in for Peak Demand.
                      </p>
                    </li>
                    <li>
                      <p>
                        The default demand profile has an evening demand peak
                        after the solar production peak at midday.
                      </p>
                    </li>
                    <li>
                      <p>
                        The graph shows the currently selected solar profile in
                        the background. This profile is updated when the user
                        updates the solar profile.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Unserved Energy Cost Profile</p>
                  <ul>
                    <li>
                      <p>
                        Unserved energy cost is the economic value to the cost
                        of electricity interruptions to electricity consumers
                        and the economy as a whole. The profile is the relative
                        cost of unserved energy over the course of the day with
                        hourly values in the range of [0, 2]. The user can
                        adjust the cost profile to have periods with higher
                        unserved energy costs such as during the peak hour
                        periods. A value of 1.0 corresponds to the value
                        inserted by the user for Unserved Energy Cost which can
                        range from $0.5-10/kWh in the developing countries
                        depending on the country.
                      </p>
                    </li>
                    <li>
                      <p>
                        The default Unserved Energy Cost profile has a higher
                        value in the evening hours.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>

              <h3>Battery Parameters</h3>
              <ul>
                <li>
                  <p>Battery Technology</p>
                  <ul>
                    <li>
                      <p>
                        There are 5 choices for battery technology that preset
                        appropriate values for the individual battery inputs
                        which can then be adjusted by the user.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Cost of Battery</p>
                  <ul>
                    <li>
                      <p>
                        The cost of the energy storage component of the plant,
                        excluding inverters and other costs.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Cost of Battery Inverter</p>
                  <ul>
                    <li>
                      <p>
                        The cost of the battery inverter and balance of plant
                        separate from the cost of the battery itself.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Energy Capacity Limit</p>
                  <ul>
                    <li>
                      <p>
                        The maximum size allowed for the battery portion of the
                        plant which can be adjusted by the user. This limits the
                        total energy storage of the system that is considered
                        within the solver.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Power Capacity Limit</p>
                  <ul>
                    <li>
                      <p>
                        The maximum size of the battery inverter. This limits
                        the peak power delivery and charging rate for the energy
                        storage system. The solver will be limited by this
                        value.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Minimum State of Charge</p>
                  <ul>
                    <li>
                      <p>
                        The amount of energy (in percentage) that must remain in
                        the battery system at all times. This reduces the
                        nominal battery capacity to the usable capacity.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Round Trip Efficiency</p>
                  <ul>
                    <li>
                      <p>
                        Accounts for the losses from the inverters,
                        charging/discharging of the battery system.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Battery Asset Life</p>
                  <ul>
                    <li>
                      <p>
                        The Economic lifetime of the battery portion of the
                        plant. This can be set separately from the lifetime of
                        the solar portion of the plant.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <h3>Economic Parameters</h3>

              <ul>
                <li>
                  <p>Unserved Energy Cost</p>

                  <ul>
                    <li>
                      Unserved energy cost is the economic value to the cost of
                      electricity interruptions to electricity consumers and the
                      economy as a whole.
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Discount Rate</p>

                  <ul>
                    <li>The cost of funds to finance the system.</li>
                  </ul>
                </li>
              </ul>

              <h2>Data Outputs</h2>

              <p>
                The solution presents the optimal sizing of the solar and
                battery storage systems for the two different applications of
                “Solar energy output smoothing” and “Solar energy output
                shifting to meet a given demand profile”.
              </p>
              <p>The summary of each output parameter is explained below:</p>

              <h3>Summary</h3>

              <ul>
                <li>
                  <p>
                    Solar Capacity. For smoothing, this is the specified Nominal
                    PV Size. For shifting, this is a component of the least cost
                    solution. An asterisk appears in the results if this
                    component is limited by the input in case of the solar
                    energy output shifting application.
                  </p>
                </li>
                <li>
                  <p>
                    BESS Energy Capacity. An asterisk appears in the results if
                    this component is limited by the input.
                  </p>
                </li>
                <li>
                  <p>
                    BESS Power Capacity. An asterisk appears in the results if
                    this component is limited by the input.
                  </p>
                </li>
                <li>
                  <p>
                    LCOE Levelized Cost of Energy, here calculated as the total
                    solution cost excluding the unserved cost, divided by the
                    energy generated by the solution.
                  </p>
                </li>
                <li>
                  <p>
                    Solar Plant Cost, calculated as the Solar Capacity * Solar
                    Cost.
                  </p>
                </li>
                <li>
                  <p>
                    BESS Energy Cost, calculated as the BESS Energy Capacity *
                    Cost of Battery.
                  </p>
                </li>
                <li>
                  <p>
                    BESS Power Cost, calculated as the BESS Power Capacity *
                    Cost of Battery Inverter.
                  </p>
                </li>
                <li>
                  <p>
                    Total Capex is the sum of Solar Plant Cost, BESS Energy
                    Cost, and BESS Power Cost.
                  </p>
                </li>
                <li>
                  <p>
                    Unserved Energy Cost is the total cost of the unserved
                    energy, including the unserved energy cost profile if
                    running the solar energy output shifting solution.
                  </p>
                </li>
              </ul>

              <h3>Time Series</h3>

              <ul>
                <li>
                  <p>
                    Load Served from Solar (Green area, top graph). The load
                    served directly from the solar PV plant.
                  </p>
                </li>
                <li>
                  <p>
                    Load served from BESS (Blue area, top graph). The load
                    served from the BESS system.
                  </p>
                </li>
                <li>
                  <p>
                    Unserved Energy (Red Area). The energy that was uneconomic
                    to serve with this solution. This is shown in context in the
                    top graph and highlighted in the bottom graph.
                  </p>
                </li>
                <li>
                  <p>
                    Solar Output (Yellow Area). The output from the Solar
                    portion of the system. This output is divided between load
                    served directly and the charging of the BESS system.
                  </p>
                </li>
                <li>
                  <p>
                    Served Energy (Black Dotted Line). The total output of this
                    solution. The sum of the served energy and the unserved
                    energy is the demand profile.
                  </p>
                </li>
                <li>
                  <p>
                    BESS Discharge per hour (Blue Area, middle graph). The
                    output from the BESS system, corresponding to the Load
                    Served from BESS.
                  </p>
                </li>
                <li>
                  <p>
                    BESS Charge per hour (Green Area, middle graph). The input
                    to the BESS system from the solar component.
                  </p>
                </li>
                <li>
                  <p>
                    BESS Stored Energy (Light Blue line). The overall state of
                    charge of the BESS energy component.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </article>
      </App>
    );
  }
}

export default Inputs;
