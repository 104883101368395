import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { environment } from "../../config";

function approx(a, b) {
  return Math.abs(a - b) < a / 100;
}

class TextResult extends Component {
  render() {
    const {
      title,
      value,
      units,
      valueClass = "",
      constraint,
      infoNote = "",
    } = this.props;
    let { constraintWording = "" } = this.props;
    const _formatter = Intl.NumberFormat("en-US", {
      maximumSignificantDigits: 3,
    });
    const fmt = (x) => _formatter.format(x);

    const isConstraint = constraint && approx(value, constraint);
    const constraintClass = (isConstraint && "textresults__constrained") || "";
    const titleClass = (infoNote && "textresults__constrained") || "";

    if (isConstraint) {
      constraintWording = `This value is limited by the input of ${constraint} ${units}`;
    } else if (constraint) {
      constraintWording = `This value range up to ${constraint}${units}`;
    }

    return (
      <div className="textresults__container">
        <div className={"textresults__title " + titleClass} title={infoNote}>
          {title}
        </div>
        <div
          className={"textresults__value " + valueClass}
          title={constraintWording}
        >
          <span className={"textresults__value_number " + constraintClass}>
            {fmt(value)}
          </span>
          <span className="textresults__value_units"> {units}</span>
        </div>
      </div>
    );
  }
}

if (environment !== "production") {
  TextResult.propTypes = {
    title: T.string,
    value: T.number,
    units: T.string,
    valueClass: T.string,
    constraint: T.number,
    infoNote: T.string,
  };
}

export default TextResult;
