import { Component, Fragment } from "react";
import { PropTypes as T } from "prop-types";
import { Group } from "@visx/group";
import { Pie } from "@visx/shape";
import { ParentSize } from "@visx/responsive";

import { environment } from "../../config";

/**
 * The CostChart component
 */
class CostChart extends Component {
  constructor(props) {
    super(props);

    this.renderChart = this.renderChart.bind(this);
  }

  renderChart(parentWidth, parentHeight) {
    const { results } = this.props;

    // Chart properties
    const height = parentHeight;
    const width = parentWidth;
    const margin = 10;

    const innerWidth = width - 2 * margin;
    const innerHeight = height - 2 * margin;
    const radius = Math.min(innerWidth, innerHeight) / 2;
    const centerY = innerHeight / 2;
    const centerX = innerWidth / 2;

    const _d = (k, v, c, s = "transparent") => {
      return {
        label: k,
        value: v,
        color: c,
        stroke: s,
      };
    };

    const parsedData = [
      _d(
        "Solar Cost",
        results.calculated.Solar_Plant_Cost,
        "#f9f5e1",
        "#efd04d"
      ),
      _d(
        "BESS Energy Cost",
        results.calculated.BESS_Energy_Cost,
        "#049FD999",
        "#049FD9"
      ),
      _d(
        "BESS Power Cost",
        results.calculated.BESS_Power_Cost,
        "#6EC99E99",
        "#6EC99E"
      ),
      _d("USE Cost", results.Unserved_Cost, "#F9BDBF", "#ff070c"),
    ];

    return (
      <figure className="results__cost_pie">
        <div className="results__cost_pie__item">
          <svg width={width} height={height}>
            <Group top={centerY + margin} left={centerX + margin}>
              <Pie
                data={parsedData}
                pieValue={(d) => d.value}
                // pieSortValues={() => -1}
                outerRadius={radius}
              >
                {(pie) => {
                  return pie.arcs
                    .map((arc, i) => {
                      return (
                        <g key={`pie-${i}`}>
                          <path
                            className="slice"
                            d={pie.path(arc)}
                            fill={arc.data.color}
                            stroke={arc.data.stroke}
                          />
                        </g>
                      );
                    })
                    .concat(
                      pie.arcs.map((arc, i) => {
                        const [centroidX, centroidY] = pie.path.centroid(arc);
                        const hasSpaceForLabel =
                          arc.endAngle - arc.startAngle >= 0.4;
                        return (
                          (hasSpaceForLabel && (
                            <g key={`label-${i}`}>
                              <text
                                fill="black"
                                x={centroidX}
                                y={centroidY}
                                dy=".33em"
                                fontSize={9}
                                textAnchor="middle"
                                pointerEvents="none"
                              >
                                {arc.data.label}
                              </text>
                            </g>
                          )) ||
                          ""
                        );
                      })
                    );
                }}
              </Pie>
            </Group>
            <rect width={innerWidth} x={margin} height={height} opacity={0} />
          </svg>
        </div>
      </figure>
    );
  }

  render() {
    return (
      <ParentSize>
        {({ width: parentWidth, height: parentHeight }) => {
          const height =
            Math.max(Math.min(parentWidth, parentHeight, 100), 140) - 7;
          return (
            (parentWidth && (
              <Fragment>{this.renderChart(height, height)}</Fragment>
            )) ||
            ""
          );
        }}
      </ParentSize>
    );
  }
}

if (environment !== "production") {
  CostChart.propTypes = {
    results: T.object,
  };
}

export default CostChart;
