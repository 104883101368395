import { Component } from "react";
import { PropTypes as T } from "prop-types";
import Filters from "./Filters";

import { environment } from "../../../config";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.renderApplyControls = this.renderApplyControls.bind(this);
  }

  renderTabContent() {
    const { filters } = this.props.model;
    const {
      filtersState,
      handleFilterChange,
      mapBounds,
      handleSelectedLocation,
      handleSolarProfile,
      handleBatteryTypeChange,
      location,
      solarProfile,
      batteryFilters,
    } = this.props;

    return (
      <Filters
        filtersConfig={filters}
        batteryFilters={batteryFilters}
        filtersState={filtersState}
        handleFilterChange={handleFilterChange}
        mapBounds={mapBounds}
        handleSelectedLocation={handleSelectedLocation}
        handleSolarProfile={handleSolarProfile}
        handleBatteryTypeChange={handleBatteryTypeChange}
        location={location}
        solarProfile={solarProfile}
      />
    );
  }

  renderApplyControls() {
    return (
      <div className="button-group button-group--horizontal">
        <button
          type="submit"
          className="econtrols__submit"
          title="Apply"
          onClick={(e) => {
            e.preventDefault();
            this.props.onApplyClick();
          }}
        >
          <span>Apply</span>
        </button>
      </div>
    );
  }

  render() {
    const { showApply } = this.props;

    return (
      <div className="econtrols">
        {this.renderTabContent()}
        <div className="econtrols__actions">
          <div />
          {(showApply && this.renderApplyControls()) || ""}
        </div>
      </div>
    );
  }
}

if (environment !== "production") {
  Dashboard.propTypes = {
    onApplyClick: T.func,
    onResetClick: T.func,
    handleFilterChange: T.func,
    model: T.object,
    filtersState: T.array,
    handleSelectedLocation: T.func,
    handleSolarProfile: T.func,
    handleBatteryTypeChange: T.func,
    location: T.array,
    solarProfile: T.array,
    mapBounds: T.array,
    batteryFilters: T.object,
    showApply: T.bool,
  };
}

export default Dashboard;
