import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { environment } from "../../config";

import ResultChart from "./ResultChart";
import ResultDownload from "./ResultDownload";
import TextResult from "./TextResult";
import ResultCostChart from "./ResultCostChart";

class Results extends Component {
  render() {
    const { results } = this.props;

    if (!results.Solar_Capacity) {
      return "";
    }

    const use_pct = results.calculated.USE_pct;
    const good_result = use_pct < 25;
    const { isShifting, renderedLocation } = results;

    return (
      (results.Solar_Capacity && (
        <div className="inpage__results">
          <div className="inpage__results_header">
            <h3>{isShifting ? "Shifting" : "Smoothing"} Results</h3>
            <div>
              {(renderedLocation &&
                "Displaying results for: " + renderedLocation) ||
                ""}
            </div>
          </div>
          <div className="inpage__textresults">
            <TextResult
              title="Solar Capacity"
              value={results.Solar_Capacity}
              units="MWp"
              constraint={
                isShifting ? results.input.Defined_Profile.Max_Solar : undefined
              }
            />
            <TextResult
              title="BESS Energy Capacity"
              value={results.BESS_Energy}
              units="MWh"
              constraint={results.input.General.Max_BESS_Energy}
            />
            <TextResult
              title="BESS Power Capacity"
              value={results.BESS_Power}
              units="MW"
              constraint={results.input.General.Max_BESS_Power}
            />
            <TextResult
              title="Unserved Energy"
              value={use_pct}
              units="%"
              valueClass={(!good_result && "danger-color") || ""}
            />
          </div>
          <ResultChart results={results} />
          <div className="inpage__textresults">
            <div className="inpage__textresults_v">
              <TextResult
                title="Solar Plant Cost"
                value={results.calculated.Solar_Plant_Cost / 1e6}
                units="(M)USD"
                valueClass="darker-primary-color"
              />
              <TextResult
                title="Unserved Energy Cost"
                value={results.Unserved_Cost / 1e6}
                units="(M)USD"
                valueClass="danger-color"
              />
            </div>
            <div className="inpage__textresults_v">
              <TextResult
                title="BESS Energy Cost"
                value={results.calculated.BESS_Energy_Cost / 1e6}
                units="(M)USD"
                valueClass="darker-primary-color"
              />
              <TextResult
                title="Total Capex"
                value={results.calculated.Total_Infra_Cost / 1e6}
                units="USD"
                valueClass="base-color"
              />
            </div>
            <div className="inpage__textresults_v">
              <TextResult
                title="BESS Power Cost"
                value={results.calculated.BESS_Power_Cost / 1e6}
                units="(M)USD"
                valueClass="darker-primary-color"
              />
              <TextResult
                title="LCOE"
                value={results.LCOE}
                units="USD/MWh"
                valueClass="base-color"
              />
            </div>
            <div className="inpage__results_pie">
              <ResultCostChart results={results} />
            </div>
          </div>
          <div className="econtrols__actions">
            <div />
            <div>
              <ResultDownload results={results} />
            </div>
          </div>
        </div>
      )) ||
      ""
    );
  }
}

if (environment !== "production") {
  Results.propTypes = {
    results: T.object,
  };
}

export default Results;
