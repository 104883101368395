import { solar_profile_1 } from "./solar_profiles";

const d_kw_to_mw = (e) => e * 1000;

/*
 * Default Profiles
 */

const demand_profile = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5,
  0.5, 1.0, 1.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0,
];

const use_profile = [
  1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
  1.0, 1.2, 1.2, 1.2, 1.2, 1.2, 1.0, 1.0, 1.0,
];

/* Individual Filters
 *
 */

const f_solar_profile = {
  label: "Solar Profile",
  id: "Solar_Profile",
  type: "solar_profile",
  unit: "W",
  defaultValue: solar_profile_1,
  section: false,
  range: [0, 1e3],
  description:
    "Solar Profile, either choose a location on the map to get monthly average values or upload a CSV profile.",
  extras: { location: [10, 16] },
};

const f_demand_profile = {
  label: "Demand Profile",
  id: "Demand",
  type: "profile_input",
  unit: "",
  defaultValue: demand_profile,
  section: "Defined_Profile",
  range: [0, 1],
  bg: "solar",
  description:
    "Profile of the demand curve for shifting, relative to the max demand specified. Click and drag the red line to change the profile. Shift click to drag a single hour of the profile.", // Instructions.
};

const f_use_profile = {
  label: "Unserved Energy Cost Profile",
  id: "USE_Cost",
  type: "profile_input",
  unit: "",
  defaultValue: use_profile,
  section: "Defined_Profile",
  range: [0, 2],
  bg: false,
  description:
    "Profile of the Unserved Energy Cost curve for shifting, relative to the unserved energy cost specified. Click and drag the red line to change the profile. Shift click to drag a single hour of the profile.",
};

const f_solar_cost = {
  label: "Solar Cost",
  id: "Solar_Inv_Cost",
  unit: "$/kW",
  defaultValue: 1000,
  section: "General",
  range: [500, 4000],
  conversion: d_kw_to_mw,
  description:
    "Total installed costs per kW of Solar PV (modules, inverter and BoS)",
};

const f_asset_life_solar = {
  label: "Solar Asset Life",
  id: "Financial_life_solar",
  unit: "yr",
  defaultValue: 25,
  section: "Settings",
  range: [5, 30],
  description:
    "Number of years that the solar plant is expected to be operational without replacement",
};

const f_asset_life_battery = (default_value) => {
  return {
    label: "Battery Asset Life",
    id: "Financial_life_battery",
    unit: "yr",
    defaultValue: default_value,
    section: "Settings",
    range: [5, 30],
    description:
      "Number of years that the battery is expected to be operational without replacement",
  };
};

const f_cost_bess_power = {
  label: "Cost of Battery Inverter",
  id: "Cost_BESS_Power",
  unit: "$/kW",
  defaultValue: 400,
  section: "General",
  range: [100, 600],
  conversion: d_kw_to_mw,
  description: "Cost of the battery inverter and balance of plant (BoP)",
};

const f_min_soc = (default_value) => {
  return {
    label: "Minimum State of Charge",
    id: "Min_SoC",
    unit: "%",
    defaultValue: default_value,
    section: "General",
    range: [0, 100],
  };
};

const f_round_trip_eff = (default_value) => {
  return {
    label: "Roundtrip Efficiency",
    id: "Round_Trip_Eff",
    unit: "%",
    defaultValue: default_value,
    section: "General",
    range: [0, 100],
  };
};

const f_max_bess_energy = {
  label: "Energy Capacity Limit",
  id: "Max_BESS_Energy",
  unit: "MWh",
  defaultValue: 5000,
  section: "General",
  range: [0, 5000],
};

const f_max_bess_power = {
  label: "Power Capacity Limit",
  id: "Max_BESS_Power",
  unit: "MW",
  defaultValue: 1000,
  section: "General",
  range: [0, 1000],
};

// const f_om_cost = {
//   label: "Operation and Maintenance Cost",
//   id: "om_cost",
//   unit: "%",
//   defaultValue: 1,
//   section: "Settings",
//   range: [0, 5],
//   description: "Operation and Maintenance Cost as a percentage of Capex",
// };

const f_battery_type = {
  label: "Battery Technology",
  id: "battery_type",
  type: "battery_type",
  defaultValue: "li-ion",
  options: [
    { value: "li-ion", title: "Li-Ion" },
    { value: "leadacid", title: "Lead Acid" },
    { value: "flow", title: "Flow" },
    { value: "nas", title: "Sodium Sulphur" },
    { value: "custom", title: "Custom Technology" },
  ],
  description:
    "Presets for the Battery Parameters corresponding to the type of battery desired.",
};

const f_max_solar = {
  label: "Solar Capacity Limit",
  id: "Max_Solar",
  unit: "MWp",
  defaultValue: 1000,
  section: "Defined_Profile",
  range: [0.3, 1000],
  description: "Maximum size of the solar plant, nominal",
  step: 0.3,
};

const f_peak_demand = {
  label: "Peak Demand",
  id: "Max_Demand",
  unit: "MW",
  defaultValue: 100,
  section: "Defined_Profile",
  range: [0.3, 250],
  description: "Maximum demand, where the demand profile = 1.0",
  step: 0.3,
};

const f_nominal_pv = {
  label: "Nominal PV Size",
  id: "Nominal_PV_size",
  unit: "MW",
  defaultValue: 100,
  section: "Smoothing",
  range: [0.3, 1000],
  description: "The rated size of the PV installation",
  step: 0.1,
};

const f_smoothing_period = {
  label: "Smoothing Period",
  id: "Smoothing_Period",
  unit: "hr",
  defaultValue: 2,
  section: "Smoothing",
  range: [1, 8],
  step: 1,
  description:
    "Time step for the smoothening function. The output ideally will be constant for the number of hours defined by the smoothing period",
};

const f_use_cost = {
  label: "Unserved Energy Cost",
  id: "Unserved_Energy_Cost",
  unit: "$/MWh",
  defaultValue: 5e3,
  section: "Settings",
  range: [1, 1e4],
  description:
    "Average economic value to the cost of interruptions to the electric customers and the country’s economy",
};

const f_discount_rate = {
  label: "Discount Rate",
  id: "Discount_rate",
  unit: "%",
  defaultValue: 5,
  section: "Settings",
  range: [0, 30],
};

/*
 * Sections
 */

const shifting = {
  label: "Solar PV Parameters",
  expanded: true,
  description: "Basic Parameters for the Time shifting to a Defined Profile",
  parameters: [
    f_solar_profile,
    f_max_solar,
    f_solar_cost,
    f_asset_life_solar,
    f_battery_type,
  ],
};

const shifting_simulation = {
  label: "Simulation Parameters",
  expanded: true,
  description: "",
  parameters: [
    f_peak_demand,
    f_demand_profile,
    f_use_cost,
    f_use_profile,
    f_discount_rate,
  ],
};

const smoothing = {
  label: "Solar PV Parameters",
  expanded: true,
  description: "Basic Parameters for Solar Profile Smoothing",
  parameters: [
    f_solar_profile,
    f_smoothing_period,
    f_solar_cost,
    f_asset_life_solar,
    f_battery_type,
  ],
};

const smoothing_simulation = {
  label: "Smoothing Parameters",
  expanded: true,
  parameters: [f_nominal_pv, f_use_cost, f_discount_rate],
};

const battery_stub = {
  label: "Batteries",
  select_field: "battery_type",
  expanded: false,
  parameters: [],
};

const lion_batteries = {
  label: "Li-Ion Batteries",
  select_field: "battery_type",
  select_value: "li-ion",
  expanded: false,
  parameters: [
    {
      label: "Cost of Battery",
      id: "Cost_BESS_Energy",
      unit: "$/kWh",
      defaultValue: 300,
      section: "General",
      range: [100, 800],
      conversion: d_kw_to_mw,
      description: "Cost of the energy component of the BESS",
    },
    f_cost_bess_power,
    f_max_bess_energy,
    f_max_bess_power,
    f_asset_life_battery(10),
    f_min_soc(10),
    f_round_trip_eff(90),
  ],
};

const leadacid_batteries = {
  label: "Lead Acid Batteries",
  select_field: "battery_type",
  select_value: "leadacid",
  expanded: false,
  parameters: [
    {
      label: "Cost of Battery",
      id: "Cost_BESS_Energy",
      unit: "$/kWh",
      defaultValue: 300,
      section: "General",
      range: [100, 800],
      conversion: d_kw_to_mw,
      description: "Cost of the energy component of the BESS",
    },
    f_cost_bess_power,
    f_max_bess_energy,
    f_max_bess_power,
    f_asset_life_battery(10),
    f_min_soc(40),
    f_round_trip_eff(85),
  ],
};

const flow_batteries = {
  label: "Flow Batteries",
  select_field: "battery_type",
  select_value: "flow",
  expanded: false,
  parameters: [
    {
      label: "Cost of Battery",
      id: "Cost_BESS_Energy",
      unit: "$/kWh",
      defaultValue: 500,
      section: "General",
      range: [200, 1500],
      conversion: d_kw_to_mw,
      description: "Cost of the energy component of the BESS",
    },
    f_cost_bess_power,
    f_max_bess_energy,
    f_max_bess_power,
    f_asset_life_battery(10),
    f_min_soc(5),
    f_round_trip_eff(80),
  ],
};

const nas_batteries = {
  label: "Sodium Sulphur Batteries",
  select_field: "battery_type",
  select_value: "nas",
  expanded: false,
  parameters: [
    {
      label: "Cost of Battery",
      id: "Cost_BESS_Energy",
      unit: "$/kWh",
      defaultValue: 240,
      section: "General",
      range: [100, 800],
      conversion: d_kw_to_mw,
      description: "Installed Energy Costs",
    },
    f_cost_bess_power,
    f_max_bess_energy,
    f_max_bess_power,
    f_asset_life_battery(20),
    f_min_soc(0),
    f_round_trip_eff(85),
  ],
};

const custom_batteries = {
  label: "Customized Battery Technology",
  select_field: "battery_type",
  select_value: "custom",
  expanded: false,
  parameters: [
    {
      label: "Cost of Battery",
      id: "Cost_BESS_Energy",
      unit: "$/kWh",
      defaultValue: 600,
      section: "General",
      range: [400, 1500],
      conversion: d_kw_to_mw,
      description: "Cost of the energy component of the BESS",
    },
    f_cost_bess_power,
    f_max_bess_energy,
    f_max_bess_power,
    f_asset_life_battery(10),
    f_min_soc(5),
    f_round_trip_eff(80),
  ],
};

const bounds = [
  { lon: -17, lat: -35 }, // w s
  { lon: 40, lat: 30 }, // e n
];

const batteries = {
  "li-ion": lion_batteries,
  leadacid: leadacid_batteries,
  flow: flow_batteries,
  nas: nas_batteries,
  custom: custom_batteries,
};

const smoothing_config = {
  params: [smoothing, battery_stub, smoothing_simulation],
  batteries,
  request: {
    Mode: "Smoothing",
    Settings: {
      Data_Period: 60,
      Num_Period: solar_profile_1.length,
    },
    General: {
      Performance_Ratio: 80,
      Solar_profile: "sprofile1",
    },
    Solar_Profile: solar_profile_1,
  },
};

const shifting_config = {
  params: [shifting, battery_stub, shifting_simulation],
  batteries,
  request: {
    Mode: "Defined_profile",
    Settings: {
      Data_Period: 60,
      Num_Period: solar_profile_1.length,
    },
    General: {
      Performance_Ratio: 80,
      Solar_profile: "sprofile1",
    },
    Solar_Profile: solar_profile_1,
  },
};

export {
  smoothing_config,
  shifting_config,
  bounds,
  solar_profile_1,
  demand_profile,
  use_profile,
};
