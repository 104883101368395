import { Component, Fragment } from "react";
import { PropTypes as T } from "prop-types";
import { Group } from "@visx/group";
import { AreaClosed } from "@visx/shape";
import { scaleLinear } from "@visx/scale";
import { AxisBottom } from "@visx/axis";
import { ParentSize } from "@visx/responsive";

import YAxis from "./YAxis";

import { environment } from "../../config";

const tickComponent = ({ formattedValue, ...tickProps }) => (
  <text {...tickProps}>{formattedValue}</text>
);

/**
 * The ProfileChart component
 */
class ProfileChart extends Component {
  constructor(props) {
    super(props);

    this.renderChart = this.renderChart.bind(this);
  }

  renderChart(parentWidth, parentHeight) {
    const data = this.props.dataSeries;

    // Chart properties
    const height = parentHeight - 16; // figcaption
    const width = parentWidth;
    const margin = {
      top: 10,
      bottom: 5,
      left: 10,
      right: 10,
    };
    const xMin = 30;
    const xMax = width - xMin;
    const yMin = height - margin.top - margin.bottom;
    const xTickLength = 6;

    const parsedData = data.map((val, idx) => {
      return {
        x: parseInt(idx),
        y: val,
      };
    });

    // Define scales
    const xScale = scaleLinear({
      range: [xMin, xMax],
      domain: [0, parsedData.length],
    });
    const yScale = scaleLinear({
      range: [margin.top, yMin],
      domain: [
        Math.max(...parsedData.map((d) => d.y)),
        Math.min(...parsedData.map((d) => d.y)),
      ],
    });

    // xlabels
    const days = Array.from(Array(parsedData.length / 24).keys()).map(
      (e) => e * 24
    );
    days.push(parsedData.length);
    const xLabelOffset = xScale(12) - xScale(0);
    const xTickProps = () => ({
      textAnchor: "middle",
      dx: -xLabelOffset,
      dy: "-0.1em",
    });

    return (
      <figure className="profile-chart-media">
        <div className="profile-chart-media__item">
          <svg width={width} height={height}>
            <Group>
              <YAxis
                xMin={xMin}
                yScale={yScale}
                textAnchor="middle"
                label="W/kWp"
              />
              <AreaClosed
                yScale={yScale}
                data={parsedData}
                x1={(d) => xScale(d.x)}
                y1={(d) => yScale(d.y)}
                strokeWidth={1}
                stroke="#efd04d"
                fill="#f9f5e1"
                fillOpacity={0.6}
              />
              <AxisBottom
                top={yMin}
                scale={xScale}
                tickValues={days}
                tickLength={xTickLength}
                tickComponent={tickComponent}
                tickLabelProps={xTickProps}
                tickFormat={(d) => d / 24}
                hideZero={true}
                className="x-axis"
                numTicks={days.length}
              />
            </Group>
            <rect width={xMax - xMin} x={xMin} height={height} opacity={0} />
          </svg>
        </div>
        <figcaption className="profile-chart-media__caption">
          {"Day"}
        </figcaption>
      </figure>
    );
  }

  render() {
    return (
      <ParentSize>
        {({ width: parentWidth, height: parentHeight }) =>
          (parentWidth && (
            <Fragment>{this.renderChart(parentWidth, parentHeight)}</Fragment>
          )) ||
          ""
        }
      </ParentSize>
    );
  }
}

if (environment !== "production") {
  ProfileChart.propTypes = {
    dataSeries: T.array,
  };
}

export default ProfileChart;
