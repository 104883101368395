import { Component } from "react";
import { PropTypes as T } from "prop-types";

import { environment } from "../../config";

class BatteryTypeInput extends Component {
  render() {
    const { handleBatteryChange, options, selected } = this.props;

    return (
      <div className="form__select-group">
        <div></div>
        <select
          onChange={handleBatteryChange}
          value={selected}
          className="form__control"
        >
          {options.map((e) => {
            return (
              <option key={e.value} value={e.value}>
                {e.title}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

if (environment !== "production") {
  BatteryTypeInput.propTypes = {
    handleBatteryChange: T.func,
    options: T.array,
    selected: T.string,
  };
}

export default BatteryTypeInput;
