import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { environment } from "../../config";

import ProfileInputChart from "./ProfileInputChart";
import CSVUpload from "./CSVUpload";

function lastUpdate() {
  return new Date().valueOf();
}

class ProfileInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSeries: this.props.dataSeries,
      showingUpload: false,
      lastUpdate: lastUpdate(),
    };

    this.handleModifyProfile = this.handleModifyProfile.bind(this);
    this.handleUploadProfile = this.handleUploadProfile.bind(this);
    this.render = this.render.bind(this);
    this.onUploadProfile = this.onUploadProfile.bind(this);
  }

  handleUploadProfile(e) {
    this.setState({
      showingUpload: false,
      dataSeries: e,
      lastUpdate: lastUpdate(),
    });
    return this.props.handleProfile(e);
  }

  handleModifyProfile(e) {
    this.setState({ dataSeries: e });
    return this.props.handleProfile(e);
  }

  onUploadProfile(e) {
    e.preventDefault();
    this.setState({ showingUpload: true });
  }

  render() {
    const { showingUpload, dataSeries, lastUpdate } = this.state;
    const { bgDataSeries, description, filterKey, label } = this.props;
    return (
      <div className="solarprofile__container">
        <div className="form__group econtrols__item">
          <div className="form__inner-header">
            <div className="form__inner-headline">
              <label className="form__label" htmlFor={filterKey}>
                {label}
              </label>
            </div>
            {description && (
              <div className="form__inner-actions">
                <button
                  type="button"
                  className="eci-info"
                  data-tip={filterKey}
                  data-for="econtrol-popover"
                  data-event="click"
                >
                  <span>Parameter info</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="solarprofile__profilecontainer">
          <ProfileInputChart
            dataSeries={dataSeries}
            bgDataSeries={bgDataSeries}
            handleDataChange={this.handleModifyProfile}
            title={this.props.title}
            yRange={this.props.yRange}
            lastUpdate={lastUpdate}
          />
        </div>
        {(showingUpload && (
          <CSVUpload
            handleUpload={this.handleUploadProfile}
            cancelUpload={() => {
              this.setState({ showingUpload: false });
            }}
            title="Upload a CSV of a Profile"
            description="The profile should be 1 column by 24 rows or in the SolarGIS format"
          />
        )) || (
          <div className="solarprofile__buttons">
            <div />
            <button
              title="Upload Profile"
              className="econtrols__upload"
              type="submit"
              onClick={this.onUploadProfile}
            >
              <span>Upload Profile</span>
            </button>
          </div>
        )}
      </div>
    );
  }
}

if (environment !== "production") {
  ProfileInput.propTypes = {
    handleProfile: T.func,
    dataSeries: T.array,
    bgDataSeries: T.array,
    title: T.string,
    yRange: T.array,
    description: T.string,
    label: T.string,
    filterKey: T.string,
  };
}

export default ProfileInput;
