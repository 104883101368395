import { Component } from "react";

import App from "./App";
import NavAboutMenu from "../components/NavAboutMenu";

class About extends Component {
  render() {
    return (
      <App pageTitle="About">
        <article className="inpage inpage--about">
          <header className="inpage__header">
            <div className="inpage__subheader">
              <div className="inpage__headline">
                <h1 className="inpage__title">About</h1>
              </div>
            </div>
          </header>
          <NavAboutMenu />
          <div className="inpage__body">
            <div className="prose">
              <h2>This tool</h2>
              <p>
                In developing countries, renewable energy with storage is
                emerging into a commercially viable alternative to fossil-based
                generation. Among the energy storage options available, battery
                storage is becoming a feasible solution to increase system
                flexibility, due to its fast response, easy deployment and cost
                reduction trends.
              </p>
              <p>
                The World Bank is supporting the sustainable scale up of
                investments in battery storage in developing countries and it
                also is convening an international partnership, the{" "}
                <a href="https://esmap.org/webpage/energy-storage-partnership-esp-factsheet">
                  Energy Storage Partnership
                </a>{" "}
                (ESP), to adapt and develop new energy storage solutions to the
                needs of developing countries. This tool has been made available
                with funding support from the Korean Green Growth Trust Fund
                (KGGTF) and technical support from the{" "}
                <a href="https://www.esmap.org/">
                  Energy Sector Management Assistance Program (ESMAP)
                </a>{" "}
                through the World Bank in context of the{" "}
                <a href="https://esmap.org/webpage/energy-storage-partnership-esp-factsheet">
                  Energy Storage Partnership
                </a>{" "}
                (ESP).
              </p>
              <p>
                Optimally sizing the energy and power components of battery
                energy storage systems (BESS) is crucial to maximize the
                benefits of hybrid solar plus storage plants. Battery sizing is
                a complex multi-dimensional problem that requires key
                performance factors such as the energy and power requirements,
                the intended application (operating regime), cost of the
                different components, among others. Defining the energy
                application intended to be supported is an essential part of
                sizing the energy storage system as it determines the number of
                hours of storage needed. This tool covers two applications:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Solar PV energy output smoothing</strong>: the BESS
                    is used to flatten the fluctuation of solar power output
                    over a period of time, thus facilitating its integration in
                    the grids. As such, the hybrid solar plus storage system can
                    provide steady power output over a desired time window,
                    usually a period of minutes to hours. Solar output smoothing
                    is equivalent to providing reserves only to address the
                    variability and uncertainty of the solar PV plant, providing
                    the necessary time for other generating units to respond.
                    This application involves dimensioning the battery for a
                    given solar PV plant.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Solar PV energy output shifting to meet a given demand
                      profile
                    </strong>
                    : the BESS is charged during RE generation hours and
                    discharged to meet the profile defined by the user. Solar
                    energy shifting guarantees a given output profile during a
                    certain time duration, thus increasing the availability of
                    round-the-clock power. This application involves
                    dimensioning the solar PV and battery systems.
                  </p>
                </li>
              </ul>
              <p>
                The objective of this tool is to provide a preliminary
                assessment of the energy storage sizing requirements (both in
                terms of energy and power), and the project cost of hybrid solar
                PV and energy storage systems, using energy storage for
                smoothing and shifting applications.
              </p>
              <p>
                As such, this tool intends to inform early discussions around
                new greenfield solar PV and battery energy storage hybrid
                projects in developing countries. The outputs of this tool
                provide only a limited assessment of the sizes of these systems
                using by default twelve representative days, each of which
                reflecting the hourly solar average daily output recorded during
                the same month. The outputs from this tool should not be taken
                as substitutes of detailed pre-feasibility or feasibility
                studies for a hybrid solar PV and battery energy storage system
                using more detailed representations of these elements.
              </p>
              <p>
                For any inquiries and potential collaboration please contact the
                development team:
              </p>
              <ul>
                <li>
                  <b>
                    World Bank/Energy Sector Management Assistance Program:{" "}
                  </b>
                  <a href="mailto:esmap@worldbank.org">esmap@worldbank.org</a>
                </li>
                <li>
                  <b>Derilinx:</b> Eric Soroos –{" "}
                  <a href="mailto:eric@derilinx.com">eric@derilinx.com</a>
                </li>
              </ul>

              <h2 className="visually-hidden">Credits</h2>
              <dl className="logo-list">
                <dd>
                  <a
                    href="https://www.worldbank.org/"
                    title="Visit World Bank"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="WBG Logo"
                      src={
                        new URL(
                          "/app/assets/graphics/content/logos/logo-wbg.png",
                          import.meta.url
                        )
                      }
                    />
                    <span>WBG</span>
                  </a>
                </dd>
                <dd>
                  <a
                    href="https://www.esmap.org/"
                    title="Visit Energy Sector Management Assistance Program"
                    className="logo-esmap"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="ESMAP Logo"
                      src={
                        new URL(
                          "/app/assets/graphics/content/logos/logo-esmap.png",
                          import.meta.url
                        )
                      }
                    />
                    <span>ESMAP</span>
                  </a>
                </dd>
              </dl>
            </div>
          </div>
        </article>
      </App>
    );
  }
}

export default About;
