import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { environment } from "../../config";
import { saveAs } from "file-saver";

import Dropdown from "../Dropdown";

import { stringify } from "csv-stringify/lib/sync";

class ResultDownload extends Component {
  constructor(props) {
    super(props);

    this.onDownloadCsv = this.onDownloadCsv.bind(this);
    this.onDownloadPdf = this.onDownloadPdf.bind(this);
    this.onDownloadJson = this.onDownloadJson.bind(this);
  }

  onDownloadCsv() {
    console.log("csv download");
    // same transform as d3 requires
    const { results } = this.props;

    let keys = [
      "Gen_From_PV",
      "BESS_In",
      "BESS_Out",
      "BESS_SOC",
      "Injected",
      "Demand",
      "Unserved",
    ];

    // need to convert from { :[], :[] } to [ { step:n, trace1:, trace2:} ]
    const data = Object.keys(results.Gen_From_PV).map((elt) => {
      return {
        step: parseInt(elt),
        ...keys.reduce((acc, trace) => {
          acc[trace] = results[trace][elt];
          return acc;
        }, {}),
      };
    });

    keys = ["step", ...keys];

    const csv_data = stringify(data, {
      columns: keys,
      header: true,
    });

    const filename = "bess_results.csv";

    const blob = new Blob([csv_data], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, filename);
  }

  onDownloadPdf() {
    /* defer loading the pdf generation */
    import("./DownloadPDF").then(({ default: downloadPDF }) =>
      downloadPDF(this.props.results)
    );
    return false;
  }

  onDownloadJson() {
    const blob = new Blob([JSON.stringify(this.props.results)], {
      type: "application/json; charset=utf8;",
    });
    saveAs(blob, "bess_results.json");
  }

  render() {
    const { results } = this.props;

    return (
      (results.Solar_Capacity && (
        <Dropdown
          className="share-menu"
          triggerClassName="econtrols__download"
          triggerActiveClassName="button--active"
          triggerText="Download"
          triggerTitle="Download full results"
          direction="up"
          alignment="right"
        >
          <h6 className="drop__title">Download</h6>
          <ul className="drop__menu drop__menu--iconified">
            <li>
              <a
                onClick={this.onDownloadCsv}
                className="drop__menu-item download-file-csv"
                title="Download results as CSV"
              >
                <span>CSV</span>
              </a>
            </li>
            <li>
              <a
                onClick={this.onDownloadPdf}
                className="drop__menu-item download-file-pdf"
                title="Download results as PDF"
              >
                <span>PDF</span>
              </a>
            </li>
            <li>
              <a
                onClick={this.onDownloadJson}
                className="drop__menu-item download-file-json"
                title="Download results as Json"
              >
                <span>JSON</span>
              </a>
            </li>
          </ul>
        </Dropdown>
      )) ||
      ""
    );
  }
}

if (environment !== "production") {
  ResultDownload.propTypes = {
    results: T.object,
  };
}

export default ResultDownload;

// <button
//   type='submit'
//   className='econtrols__download'
//   title='Download'
//   onClick={e => {
//     e.preventDefault();
//     this.props.onApplyClick();
//   }}
//   >
//   <span>Download</span>
// </button>
