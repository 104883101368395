import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import {
  Navigate,
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { smoothing_config, shifting_config } from "./models";

// Views
import Home from "./views/Home";
import Smoothing from "./views/Smoothing";

import About from "./views/About";
import GettingStarted from "./views/GettingStarted";
import UserGuide from "./views/UserGuide";
import Inputs from "./views/Inputs";
import Exports from "./views/Exports";
import Methodology from "./views/Methodology";
import UhOh from "./views/UhOh";

// Store
import { store } from "./store";

const withNavigate = (Element) => {
  return function NavigateWrapper(props) {
    return <Element {...props} navigate={useNavigate()} />;
  };
};
const NavigableSmoothing = withNavigate(Smoothing);

const root = createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/index.html" element={<Navigate to="/" />} />
          <Route
            path="/smoothing"
            element={
              <NavigableSmoothing
                config={smoothing_config}
                key="smoothing-app"
              />
            }
          />
          <Route
            path="/shifting"
            element={
              <NavigableSmoothing config={shifting_config} key="shifting-app" />
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/getting_started" element={<GettingStarted />} />
          <Route path="/user_guide" element={<UserGuide />} />
          <Route path="/inputs" element={<Inputs />} />
          <Route path="/exports" element={<Exports />} />
          <Route path="/methodology" element={<Methodology />} />
          <Route path="*" element={<UhOh />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </HelmetProvider>
);
