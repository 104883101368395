import { Component } from "react";
import { NavLink } from "react-router-dom";

const NavL = ({ title, to, subclass, children }) => (
  <NavLink
    title={title}
    to={to}
    className={({ isActive }) =>
      `about-menu__link about-menu__link--${subclass} ${
        isActive ? "about-menu__link--active" : ""
      }`
    }
  >
    {children}
  </NavLink>
);

export default class NavAboutMenu extends Component {
  render() {
    return (
      <ul className="about-menu nav">
        <li>
          <NavL to="/about" title="Learn about this platform" subclass="about">
            <span>Introduction</span>
          </NavL>
        </li>
        <li>
          <NavL to="/getting_started" title="How to start" subclass="start">
            <span>Getting Started</span>
          </NavL>
        </li>
        <li>
          <NavL to="/user_guide" title="user guide" subclass="start">
            <span>User Guide</span>
          </NavL>
        </li>
        <li>
          <NavL
            to="/inputs"
            title="Inputs and Output Parameters"
            subclass="input"
          >
            <span>Inputs and Outputs</span>
          </NavL>
        </li>
        <li>
          <NavL to="/exports" title="Export Details" subclass="export">
            <span>Export Formats</span>
          </NavL>
        </li>
        <li>
          <NavL
            to="/methodology"
            title="Solver Methodology"
            subclass="methodology"
          >
            <span>Methodology</span>
          </NavL>
        </li>
        {/*
        <li>
          <NavL
            to="/resources"
            title="Resources"
            subclass="resources"
          >
            <span>Additional Resources</span>
          </NavL>
        </li>
        <li>
          <NavL
            to="/release_notes"
            title="Release Notes"
            subclass="release"
          >
            <span>Release Notes</span>
          </NavL>
        </li>
        */}
      </ul>
    );
  }
}
