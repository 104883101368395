import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { NavLink } from "react-router-dom";

import { environment } from "../config";

const NavL = ({ title, to, subclass, children, end = false }) => (
  <NavLink
    title={title}
    to={to}
    end={end}
    className={({ isActive }) =>
      `global-menu__link global-menu__link--${subclass} ${
        isActive ? "global-menu__link--active" : ""
      }`
    }
  >
    {children}
  </NavLink>
);

export default class NavGlobalMenu extends Component {
  renderHeaderMenu() {
    return (
      <ul className="global-menu">
        <li>
          <NavL end to="/" title="Visit the home page" subclass="home">
            <span>Home</span>
          </NavL>
        </li>
        <li>
          <NavL to="/smoothing" title="Smoothing" subclass="smoothing">
            <span>Smoothing</span>
          </NavL>
        </li>
        <li>
          <NavL to="/shifting" title="Shifting" subclass="shifting">
            <span>Shifting</span>
          </NavL>
        </li>
        <li>
          <NavL to="/about" title="Learn about this platform" subclass="about">
            <span>About</span>
          </NavL>
        </li>
      </ul>
    );
  }

  renderFooterMenu() {
    return <ul />;
  }

  render() {
    const { forHeader, forFooter } = this.props;
    if (forHeader) return this.renderHeaderMenu();
    if (forFooter) return this.renderFooterMenu();
    return null;
  }
}

if (environment !== "production") {
  NavGlobalMenu.propTypes = {
    forHeader: T.bool,
    forFooter: T.bool,
  };
}
