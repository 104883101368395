import { Component } from "react";

import App from "./App";
import NavAboutMenu from "../components/NavAboutMenu";

class GettingStarted extends Component {
  render() {
    return (
      <App pageTitle="Getting Started">
        <article className="inpage inpage--about">
          <header className="inpage__header">
            <div className="inpage__subheader">
              <div className="inpage__headline">
                <h1 className="inpage__title">Getting Started</h1>
              </div>
            </div>
          </header>
          <NavAboutMenu />
          <div className="inpage__body">
            <div className="prose">
              <video controls width="100%">
                <source
                  src="https://storagesizing.energydata.info/Solar_PV_1-25-2021.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </article>
      </App>
    );
  }
}

export default GettingStarted;
