import { Component, Fragment } from "react";
import { PropTypes as T } from "prop-types";
import clone from "lodash.clone";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import { LinePath } from "@visx/shape";
import { scaleLinear } from "@visx/scale";
import { AxisBottom } from "@visx/axis";
import { ParentSize } from "@visx/responsive";
import { Drag } from "@visx/drag";

import YAxis from "./YAxis";

import { environment } from "../../config";

/**
 * The ProfileInputChart component
 * This item is a simple line chart, with dragging to modify the USE cost profile or the Demand Curve.
 */
class ProfileInputChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDragging: false,
      data: this.props.dataSeries,
      lastUpdate: this.props.lastUpdate,
    };

    this.renderChart = this.renderChart.bind(this);
    this.handleDragMove = this.handleDragMove.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.clampY = this.clampY.bind(this);
  }

  clampY(y) {
    const [yMin, yMax] = this.props.yRange;
    // quantized to 0.05
    const yQuantized = Math.round(y * 20.0) / 20.0;
    return Math.max(Math.min(yQuantized, yMax), yMin);
  }

  yGroup(dragX, data, dy) {
    // move all of the values around dragX that are the same y
    // up or down by the same amount.

    const origY = data[dragX];
    const newY = this.clampY(data[dragX] + dy);

    data[dragX] = newY;
    let i = dragX;
    while (i-- >= 0 && data[i] == origY) {
      data[i] = newY;
    }
    i = dragX;
    while (i++ < data.length && data[i] == origY) {
      data[i] = newY;
    }
    return data;
  }

  xGroup(dragX, data, dx) {
    const newY = data[dragX];
    const dragDest = Math.round(dragX + dx);
    const startX = Math.max(Math.min(dragX, dragDest), 0);
    const endX = Math.min(Math.max(dragX, dragDest), data.length);

    for (let i = startX; i < endX; i++) {
      data[i] = newY;
    }
    return data;
  }

  handleDragStart() {
    // x, y in logical units

    let data;
    if (this.state.lastUpdate >= this.props.lastUpdate) {
      data = this.state.data;
    } else {
      data = this.props.dataSeries;
    }

    this.setState({
      isDragging: true,
      predragData: clone(data),
      lastUpdate: new Date().valueOf(),
    });
    return false;
  }

  handleDragMove(e, x, y, dx, dy) {
    // x, y in logical units
    const dragX = Math.round(x);
    let data = clone(this.state.predragData);

    if (Math.abs(e.dx) > Math.abs(e.dy)) {
      // x swipe
      data = this.xGroup(dragX, data, dx);
    } else {
      if (e.event.shiftKey) {
        // shift y
        data[dragX] = this.clampY(data[dragX] + dy);
      } else {
        // y swipe
        data = this.yGroup(dragX, data, dy);
      }
    }

    this.setState({ data });
    return false;
  }

  handleDragEnd() {
    // x, y in logical units
    console.log("drag end");

    const { data } = this.state;

    this.setState({
      isDragging: false,
    });

    this.props.handleDataChange(data);

    return false;
  }

  renderChart(parentWidth, parentHeight) {
    let data;
    if (this.state.lastUpdate >= this.props.lastUpdate) {
      data = this.state.data;
    } else {
      data = this.props.dataSeries;
    }
    const { bgDataSeries } = this.props;

    // Chart properties
    const height = parentHeight - 16; // figcaption
    const width = parentWidth;
    const margin = {
      top: 20,
      bottom: 5,
      left: 10,
      right: 10,
    };
    const xMin = 30;
    const xMax = width - xMin;
    const yMin = height - margin.top - margin.bottom;
    const xTickLength = 6;

    const parsedData = data.map((val, idx) => {
      return {
        x: parseInt(idx),
        y: val,
      };
    });

    const parsedBgData =
      bgDataSeries &&
      bgDataSeries.map((val, idx) => {
        return {
          x: parseInt(idx),
          y: val,
        };
      });

    // Define scales
    const xScale = scaleLinear({
      range: [xMin, xMax],
      domain: [0, parsedData.length - 1],
    });
    const yScale = scaleLinear({
      range: [margin.top, yMin],
      domain: [Math.max(...this.props.yRange), Math.min(...this.props.yRange)],
    });

    let bgYScale;
    if (bgDataSeries) {
      bgYScale = scaleLinear({
        range: [margin.top, yMin],
        domain: [Math.max(...this.props.bgDataSeries), 0],
      });
    }

    return (
      <figure className="profile-chart-media">
        <div className="profile-chart-media__item">
          <svg width={width} height={height}>
            <Group>
              <YAxis
                xMin={xMin}
                yMin={2}
                yScale={yScale}
                label="Factor"
                textAnchor="middle"
                numTicks={5}
              />
              <GridRows
                lineStyle={{ pointerEvents: "none" }}
                scale={yScale}
                width={xMax - xMin}
                left={xMin}
                strokeDasharray="2,2"
                stroke="#eee"
              />
              {(bgDataSeries && (
                <LinePath
                  data={parsedBgData}
                  x={(d) => xScale(d.x % parsedData.length)}
                  y={(d) => bgYScale(d.y)}
                  strokeWidth={1}
                  stroke="#cad9eb"
                  opacity={0.75}
                />
              )) ||
                ""}
              <LinePath
                data={parsedData}
                x={(d) => xScale(d.x)}
                y={(d) => yScale(d.y)}
                strokeWidth={2}
                stroke="#f66"
              />
              <AxisBottom
                top={yMin}
                scale={xScale}
                data={parsedData}
                tickFormat={(d) => d}
                tickLength={xTickLength}
              />
            </Group>
            <Drag
              width={xMax - xMin}
              height={height}
              resetOnStart={true}
              onDragMove={(e) => {
                this.handleDragMove(
                  e,
                  xScale.invert(e.x),
                  yScale.invert(e.y),
                  xScale.invert(e.dx + e.x) - xScale.invert(e.x),
                  yScale.invert(e.dy + e.y) - yScale.invert(e.y)
                );
              }}
              onDragStart={(e) => {
                this.handleDragStart(e, xScale.invert(e.x), yScale.invert(e.y));
              }}
              onDragEnd={(e) => {
                this.handleDragEnd(
                  e,
                  xScale.invert(e.x),
                  yScale.invert(e.y),
                  xScale.invert(e.dx),
                  yScale.invert(e.dy)
                );
              }}
            >
              {({ dragStart, dragEnd, dragMove }) => {
                return (
                  <rect
                    width={xMax - xMin}
                    x={xMin}
                    height={height}
                    opacity={0}
                    onMouseDown={dragStart}
                    onMouseUp={dragEnd}
                    onMouseMove={dragMove}
                    onTouchStart={dragStart}
                    onTouchEnd={dragEnd}
                    onTouchMove={dragMove}
                  />
                );
              }}
            </Drag>
          </svg>
        </div>
        {this.props.title && (
          <figcaption className="profile-chart-media__caption">
            {"Hour"}
          </figcaption>
        )}
      </figure>
    );
  }

  render() {
    return (
      <ParentSize>
        {({ width: parentWidth, height: parentHeight }) =>
          (parentWidth && (
            <Fragment>{this.renderChart(parentWidth, parentHeight)}</Fragment>
          )) ||
          ""
        }
      </ParentSize>
    );
  }
}

if (environment !== "production") {
  ProfileInputChart.propTypes = {
    dataSeries: T.array,
    bgDataSeries: T.array,
    handleDataChange: T.func,
    yRange: T.array,
    title: T.string,
    lastUpdate: T.number,
  };
}

export default ProfileInputChart;
