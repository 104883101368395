import React, { Component } from "react";
import { PropTypes as T } from "prop-types";
import InputRange from "react-input-range";
import c from "classnames";
import Collapsible from "react-collapsible";

import { environment } from "../../../config";

import ShadowScrollbars from "../../ShadowScrollbar";
import SolarProfile from "../SolarProfile";
import ProfileInput from "../ProfileInput";
import BatteryTypeInput from "../BatteryTypeInput";
import FilterItem from "../FilterItem";

class Filters extends Component {
  constructor(props) {
    super(props);

    this.renderRangeFilter = this.renderRangeFilter.bind(this);
    this.renderProfileInput = this.renderProfileInput.bind(this);
    this.renderSolarProfile = this.renderSolarProfile.bind(this);
  }

  renderRangeFilter(filter, filterIdx, groupIdx) {
    const filterState =
      this.props.filtersState[groupIdx] &&
      this.props.filtersState[groupIdx][filterIdx];
    const [min, max] = filter.range;
    const defaultValue = filter.defaultValue;

    return (
      <FilterItem
        filter={filter}
        groupIdx={groupIdx}
        filterIdx={filterIdx}
        key={filter.id}
      >
        <div className="form__slider-group">
          <InputRange
            minValue={min}
            maxValue={max}
            name={`slider-${filter.id}`}
            id={`slider-${filter.id}`}
            value={filterState === undefined ? defaultValue : filterState}
            onChange={this.props.handleFilterChange.bind(
              this,
              groupIdx,
              filterIdx
            )}
            step={filter.step}
          />
          <ManualInput
            id={`input-${filter.id}`}
            label="Value"
            value={filterState === undefined ? defaultValue : filterState}
            min={min}
            max={max}
            onChange={this.props.handleFilterChange.bind(
              this,
              groupIdx,
              filterIdx
            )}
          />
        </div>
      </FilterItem>
    );
  }

  renderSolarProfile(filter, filterIdx, groupIdx) {
    const {
      mapBounds,
      handleSelectedLocation,
      handleSolarProfile,
      location,
      solarProfile,
    } = this.props;
    return (
      <SolarProfile
        key={filter.id}
        mapBounds={mapBounds}
        handleSelectedLocation={handleSelectedLocation}
        handleSolarProfile={handleSolarProfile}
        location={location}
        solarProfile={solarProfile}
        description={filter.description}
        label={filter.label}
        filterKey={`filter-${groupIdx}-${filterIdx}`}
      />
    );
  }

  renderProfileInput(filter, filterIdx, groupIdx) {
    const filterState =
      this.props.filtersState[groupIdx] &&
      this.props.filtersState[groupIdx][filterIdx];
    const defaultValue = filter.defaultValue;
    const { solarProfile } = this.props;

    return (
      <ProfileInput
        title="Demand Profile"
        key={filter.id}
        handleProfile={this.props.handleFilterChange.bind(
          this,
          groupIdx,
          filterIdx
        )}
        dataSeries={filterState || defaultValue}
        bgDataSeries={(filter.bg == "solar" && solarProfile) || undefined}
        yRange={filter.range}
        description={filter.description}
        label={filter.label}
        filterKey={`filter-${groupIdx}-${filterIdx}`}
      />
    );
  }

  renderBatteryTypeInput(filter, filterIdx, groupIdx) {
    const filterState =
      this.props.filtersState[groupIdx] &&
      this.props.filtersState[groupIdx][filterIdx];
    const defaultValue = filter.defaultValue;

    return (
      <FilterItem
        key={filter.id}
        filter={filter}
        groupIdx={groupIdx}
        filterIdx={filterIdx}
      >
        <BatteryTypeInput
          handleBatteryChange={this.props.handleBatteryTypeChange.bind(
            this,
            groupIdx,
            filterIdx
          )}
          options={filter.options}
          selected={filterState || defaultValue}
        />
      </FilterItem>
    );
  }

  renderGroup(filters, groupIdx) {
    let header;
    // missing -> expand
    const expanded = !(filters.expanded === false);
    if (filters.label) {
      header = <h3> {filters.label} </h3>;
    }
    return (
      <Collapsible
        trigger={header}
        open={expanded}
        lazyRender={!!filters.lazyRender}
        key={"collapsible-group-" + groupIdx}
      >
        {filters.parameters.map((filter, filterIdx) => {
          const filter_type = filter.type;
          if (filter_type == "profile_input") {
            return this.renderProfileInput(filter, filterIdx, groupIdx);
          }
          if (filter_type == "solar_profile") {
            return this.renderSolarProfile(filter, filterIdx, groupIdx);
          }
          if (filter_type == "battery_type") {
            return this.renderBatteryTypeInput(filter, filterIdx, groupIdx);
          }
          return this.renderRangeFilter(filter, filterIdx, groupIdx);
        })}
      </Collapsible>
    );
  }

  renderFilters(filters) {
    return filters.map((group, groupIdx) => {
      if (group.select_field == "battery_type") {
        return this.renderGroup(this.props.batteryFilters, groupIdx);
      }
      return this.renderGroup(group, groupIdx);
    });
  }

  render() {
    const { filtersConfig } = this.props;

    return (
      <section className="econtrols__section" id="econtrols-filters">
        <h1 className="econtrols__title">Filters</h1>
        <form className="form econtrols__block" id="#econtrols__scenarios">
          <div className="econtrols__subblock">
            <ShadowScrollbars theme="light">
              {filtersConfig &&
                filtersConfig.length > 0 &&
                this.renderFilters(filtersConfig)}
            </ShadowScrollbars>
          </div>
        </form>
      </section>
    );
  }
}

if (environment !== "production") {
  Filters.propTypes = {
    filtersConfig: T.array,
    filtersState: T.array,
    handleFilterChange: T.func,
    handleSelectedLocation: T.func,
    handleSolarProfile: T.func,
    handleBatteryTypeChange: T.func,
    batteryFilters: T.object,
    location: T.array,
    solarProfile: T.array,
    mapBounds: T.array,
  };
}

export default Filters;

/**
 * Input field for numeric values that errors when the value is not
 * a number or is outside the min/max range
 */
class ManualInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.timeoutId = false;

    this.state = {
      originalVal: props.value,
      value: props.value,
      errored: false,
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  isValid(v) {
    const { min, max } = this.props;
    const value = Number(v);
    return !isNaN(value) && min <= value && value <= max;
  }

  componentDidUpdate(nextProps) {
    if (this.state.originalVal !== nextProps.value) {
      this.setState({
        value: nextProps.value,
        originalVal: nextProps.value,
      });
    }
  }

  onValueChange(e) {
    try {
      // special case: They've cleared the item, don't cause any validation
      // or other action, just let them do that.
      if (!e.target.value.length) {
        this.setState({ value: "" });
        return;
      }
      console.log("onChange:" + e.target.value);
      console.log("originalState:" + this.state.value);
      // happy case, they've typed something.
      const value = Number(e.target.value);

      // if they have typed '0', or '0.' we want to let them continue
      if (value == 0 || isNaN(value)) {
        console.log("continuing " + e.target.value);
        this.setState({ value: e.target.value });
        return;
      }
      console.log("setting numeric state: " + value);
      this.setState({ value });
      // Trigger a refresh in a little while if they've just typed something
      // and then wait around for results.
      this.timeoutId && window.clearTimeout(this.timeoutId);
      this.timeoutId = window.setTimeout(this.onFieldBlur, 500);
    } catch (exp) {
      console.log(exp);
      // this.setState({ errored: true });
    }
  }

  onFieldBlur() {
    const { value, originalVal } = this.state;
    const { onChange } = this.props;
    console.log("fieldBlur");
    // kill any timeouts that might trigger this.
    this.timeoutId && window.clearTimeout(this.timeoutId);

    if (value === "" || !this.isValid(value)) {
      this.setState({
        value: originalVal,
        errored: true,
      });
      // We have to clear the error state after the animation so it can
      // error again.
      window.setTimeout(() => {
        this.setState({ errored: false });
      }, 550);
    } else {
      // all good.
      this.setState({ errored: false, originalVal: value });
      onChange(value);
    }
  }

  fmt(v) {
    const _formatter = Intl.NumberFormat("en-US", {
      maximumSignificantDigits: 3,
    });
    const fmt = (x) => _formatter.format(x);

    const value = Number(v);

    // if they have typed '0', or '0.' we want to let them continue
    if (value == 0 || isNaN(value)) {
      return v;
    }
    return fmt(value);
  }

  render() {
    const { id, label } = this.props;

    return (
      <div>
        <label htmlFor={id}>{label}</label>
        <input
          type="text"
          pattern="[0-9,.]+"
          name={id}
          id={id}
          className={c("form__control form__control--small", {
            "form__control--invalid": this.state.errored,
          })}
          value={this.fmt(this.state.value)}
          onBlur={this.onFieldBlur}
          onChange={this.onValueChange}
        />
      </div>
    );
  }
}

if (environment !== "production") {
  ManualInput.propTypes = {
    id: T.string,
    label: T.string,
    value: T.number,
    min: T.number,
    max: T.number,
    onChange: T.func,
  };
}
