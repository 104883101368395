import { Component } from "react";

import App from "./App";
import NavAboutMenu from "../components/NavAboutMenu";

class Exports extends Component {
  render() {
    return (
      <App pageTitle="Export Formats">
        <article className="inpage inpage--about">
          <header className="inpage__header">
            <div className="inpage__subheader">
              <div className="inpage__headline">
                <h1 className="inpage__title">Export Formats</h1>
              </div>
            </div>
          </header>
          <NavAboutMenu />
          <div className="inpage__body">
            <div className="prose">
              <h3>CSV Export</h3>

              <p>CSVs are exported with these columns:</p>

              <ul>
                <li>Step: Simulation Timestep number, each step is 1 hour</li>
                <li>Gen_From_PV: Solar output (MW)</li>
                <li>BESS_In: BESS Charge per hour (MW)</li>
                <li>BESS_Out: BESS Discharge per hour (MW)</li>
                <li>BESS_SOC: BESS State of Charge (MWh)</li>
                <li>Injected: Served Energy (MW)</li>
                <li>
                  Demand: The calculated demand curve, from the Demand Profile
                  and Peak Demand. (MW)
                </li>
                <li>Unserved: Unserved Energy (MW)</li>
              </ul>

              <h3>JSON Export</h3>

              <p>
                The JSON export contains all of the time series included in the
                CSV Export, as well as the following results and calculated
                fields.
              </p>

              <ul>
                <li>
                  Annualized_Capex = Annual Solar Plant Cost + Annual BESS
                  Energy Cost + Annual BESS Power Cost (USD)
                </li>
                <li>
                  Average_Cost = Total Cost / Total Demanded Energy (USD/MWh)
                </li>
                <li>BESS_Energy: Solution output, BESS Energy Capacity (MW)</li>
                <li>BESS_Power: Solution output, BESS Power Capacity (MW)</li>
                <li>
                  LCOE = (Total Cost - Unserved Energy Cost) / Energy Produced
                  (USD/MWh)
                </li>
                <li>Solar_Capacity: Solution output, Solar Capacity (MW)</li>
                <li>Total_Cost: Objective Function Value (USD)</li>
                <li>
                  <p>
                    Unserved_Cost: Solution output, Total Unserved Energy Cost
                    (USD)
                  </p>
                </li>
                <li>
                  <p>Calculated fields</p>

                  <ul>
                    <li>Total_Demand: sum(demand) (MW)</li>
                    <li>Total_Unserved_Energy: sum(Unserved) (MW)</li>
                    <li>USE_pct: Unserved Energy as a percentage of demand</li>
                    <li>Solar_Plant_Cost: Solar Capacity * Solar Cost, USD</li>
                    <li>
                      BESS_Energy_Cost: BESS Energy Capacity * BESS Energy Cost,
                      USD
                    </li>
                    <li>
                      BESS_Power_Cost: BESS Power Capacity * BESS Energy Cost,
                      USD
                    </li>
                    <li>
                      Total_Infra_Cost: Solar + BESS Energy * BESS Power, USD
                    </li>
                  </ul>
                </li>
                <p />
                <li>isShifting/isSmoothing: which simulation was run.</li>
                <li>
                  input: The raw data sent to the API to generate this response.
                </li>
              </ul>
            </div>
          </div>
        </article>
      </App>
    );
  }
}

export default Exports;
