import { Component } from "react";
import { PropTypes as T } from "prop-types";

import { environment } from "../../config";

class FilterItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { filter, groupIdx, filterIdx } = this.props;

    return (
      <div className="form__group econtrols__item" key={`${filter.id}`}>
        <div className="form__inner-header">
          <div className="form__inner-headline">
            <label className="form__label" htmlFor={`slider-${filter.id}`}>
              {filter.label} {(filter.unit && " (" + filter.unit + ")") || ""}
            </label>
          </div>
          {filter.description && (
            <div className="form__inner-actions">
              <button
                type="button"
                className="eci-info"
                data-tip={`filter-${groupIdx}-${filterIdx}`}
                data-for="econtrol-popover"
                data-event="click"
              >
                <span>Parameter info</span>
              </button>
            </div>
          )}
        </div>
        {this.props.children}
      </div>
    );
  }
}

if (environment !== "production") {
  FilterItem.propTypes = {
    filter: T.object,
    groupIdx: T.number,
    filterIdx: T.number,
  };
}

export default FilterItem;
