export default {
  appTitle: "SolarPV + Storage",
  appShortTitle: "PV",
  appDescription: "Explore least cost solar and storage sizing options.",
  baseUrl: "http://localhost:9000",
  dataServiceUrl: "http://localhost:5000",
  basemapStyleLink: "mapbox://styles/derilinx/ck3yqjovg4dpn1crwajrc9ajr",
  mapboxAccessToken:
    "pk.eyJ1IjoiZGVyaWxpbngiLCJhIjoiY2szeTlzbWo2MDV6eDNlcDMxM3dzZXBieiJ9.zPf1iiFilYYwyx6ETNj_8w",
};
