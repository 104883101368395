import { Component } from "react";
import { connect } from "react-redux";
import { PropTypes as T } from "prop-types";
import clone from "lodash.clone";
import isEqual from "lodash.isequal";

import { environment } from "../config";
import { cloneArrayAndChangeCell, round } from "../utils";
import { wrapApiResult } from "../redux/utils";
import {
  fetchSmoothing,
  fetchAltitude,
  fetchSolarProfile,
} from "../redux/actions";
import QsState from "../utils/qs-state";
import { Tooltip as ReactTooltip } from "react-tooltip";

import App from "./App";
import Breakpoint from "../components/Breakpoint";
import Dashboard from "../components/explore/dashboard";
import Results from "../components/explore/Results";

import { solar_profile_1, bounds } from "../models";

class Smoothing extends Component {
  constructor(props) {
    super(props);

    this.onApplyClick = this.onApplyClick.bind(this);
    this.onResetClick = this.onResetClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleLayerChange = this.handleLayerChange.bind(this);
    this.handleSelectedLocation = this.handleSelectedLocation.bind(this);
    this.handleSolarProfile = this.handleSolarProfile.bind(this);
    this.handleBatteryTypeChange = this.handleBatteryTypeChange.bind(this);
    this.popoverRenderFn = this.popoverRenderFn.bind(this);
    this.toggleDashboard = this.toggleDashboard.bind(this);

    const compatClass =
      (/iPhone/.test(window.navigator.platform) && "ios") ||
      (/Win/.test(window.navigator.platform) && "win") ||
      "";

    this.state = {
      dashboardChangedAt: Date.now(),
      defaultFilters: [],
      filtersState: [],
      layersState: [],
      appliedState: {},
      results: {},
      altitude: 0,
      location: [10.0, 16.0], // default location should not be 0,0, because GSA doesn't handle water
      solarProfile: solar_profile_1,
      batteryType: "li-ion",
      nextRequest: false,
      dashboardVisible: true,
      compatClass,
      renderedLocation: undefined,
    };

    // Setup the qsState for url state management.
    this.qsState = new QsState({
      // The filters have a complex structure.
      // To ensure that the look good on the url and that it doesn't get too
      // big, we're encoding them.
      filters: {
        // filters are encoded as [group]||[group] where group == [val]|[val]
        accessor: "filtersState",
        hydrator: (v) => {
          if (!v) return null;
          const groups = v.split("||");
          return groups.map((g) => {
            g.split("|").map((p) => {
              return parseFloat(p);
            });
          });
        },
        dehydrator: (v) => {
          if (!v) return null;
          return v
            .map((group) => {
              if (!group) {
                return "0";
              }
              return group
                .map((s) => {
                  return `${s || 0}`;
                })
                .join("|");
            })
            .join("||");
        },
      },
    });
  }

  async componentDidMount() {
    // this.getProfileForLocation();
    this.updateScenario();
  }

  async getProfileForLocation() {
    const { location } = this.state;
    await this.props.fetchAltitude(location);
    const { hasError, getData } = this.props.altitude;
    let altitude = 0;
    if (!hasError()) {
      altitude = getData();
    }
    await this.props.fetchSolarProfile(location, altitude);
    const { hasError: profileError, getData: profileData } =
      this.props.solarProfile;

    if (!profileError()) {
      this.setState({
        altitude: getData(),
        solarProfile: profileData(),
      });
      this.updateScenario();
    } else {
      console.log("Error fetching solar data");
    }
  }

  handleBatteryTypeChange(groupIdx, filterIdx, e) {
    const filtersState = clone(this.state.filtersState);
    const defaultFilters = clone(this.state.defaultFilters);
    const filters = this.props.config.params;

    const batteryGroupIdx = filters.findIndex(
      (e) => e.select_field == "battery_type"
    );
    // clear the existing data.
    filtersState[batteryGroupIdx] = [];
    defaultFilters[batteryGroupIdx] = [];

    // set the battery type in the filterstate
    if (!filtersState[groupIdx]) {
      filtersState[groupIdx] = [];
    }
    filtersState[groupIdx][filterIdx] = e.target.value;

    this.setState(
      {
        batteryType: e.target.value,
        defaultFilters,
        filtersState,
      },
      this.updateScenario
    );
  }

  popoverRenderFn(code) {
    if (code === null) return "";
    // Because of the way that ReactTooltip works, code has to be a string.
    // It has the following format: <type>-<group>-<filter>
    // Example: lever-0

    const { params, batteries } = this.props.config;
    const { batteryType } = this.state;

    const batteryFilters = batteries[batteryType];

    const match = code.match(/^([a-z0-9]+)-([0-9]+)-([0-9]+)/);
    if (!match) return "";

    const [, , s_groupIdx, s_filterIdx] = match;
    const filterIdx = parseInt(s_filterIdx);
    const groupIdx = parseInt(s_groupIdx);

    const group = params[groupIdx];
    let obj;

    if (group.select_field == "battery_type") {
      obj = batteryFilters.parameters[filterIdx];
    } else {
      obj = group.parameters[filterIdx];
    }

    if (!obj) {
      return "";
    }

    return (
      <div className="popover__contents">
        <div className="popover__body">{obj.description}</div>
      </div>
    );
  }

  toggleDashboard() {
    const { dashboardVisible } = this.state;

    this.setState({
      dashboardVisible: !dashboardVisible,
    });
  }

  handleSelectedLocation(location, geoloc) {
    const renderedLocation =
      geoloc || location.map((x) => round(x, 4)).join(", ");
    this.setState({ location, renderedLocation });
    this.getProfileForLocation();
  }

  handleSolarProfile(profile) {
    this.setState(
      { solarProfile: profile, renderedLocation: "Custom Solar Profile" },
      this.updateScenario
    );
  }

  handleFilterChange(groupIdx, filterIdx, value) {
    const { params, batteries } = this.props.config;
    const { batteryType } = this.state;

    let group = params[groupIdx];
    if (group.select_field == "battery_type") {
      group = batteries[batteryType];
    }

    const filter = group.parameters[filterIdx];
    const filtersState = clone(this.state.filtersState);
    const defaultFilters = clone(this.state.defaultFilters);

    let newValue = value;

    // Ensure that range values are between min and max
    const [min, max] = filter.range;
    if (newValue <= min) {
      newValue = min;
    }

    // Compare using Math.floor because the input uses step=1 and returns a lower integer value when max is float.
    if (newValue >= Math.floor(max)) {
      newValue = max;
    }

    if (!filtersState[groupIdx]) {
      filtersState[groupIdx] = [];
    }
    filtersState[groupIdx][filterIdx] = newValue;

    // Set flag if filter is not default
    if (!defaultFilters[groupIdx]) {
      defaultFilters[groupIdx] = [];
    }
    defaultFilters[groupIdx][filterIdx] = isEqual(
      filter.defaultValue,
      newValue
    );

    this.setState(
      {
        defaultFilters,
        filtersState,
      },
      this.updateScenario
    );
  }

  handleLayerChange(leverIdx) {
    const active = this.state.layersState[leverIdx];
    const layersState = cloneArrayAndChangeCell(
      this.state.layersState,
      leverIdx,
      !active
    );

    this.setState({ layersState });
  }

  handleYearChange(year) {
    this.setState({ year });
  }

  onApplyClick() {
    // Update location.
    const qString = this.qsState.getQs(this.state);
    this.props.navigate(`?${qString}`);
    this.toggleDashboard();
    this.updateScenario();
  }

  onResetClick() {
    this.setState(
      {
        filtersState: [],
      },
      () => {
        this.onApplyClick();
      }
    );
  }

  filterToRequest(state) {
    const { filtersState, solarProfile, batteryType } = state;
    const filters = this.props.config.params;

    const req = { ...this.props.config.request };

    for (let groupIdx = 0; groupIdx < filters.length; groupIdx++) {
      let group = filters[groupIdx].parameters;
      if (filters[groupIdx].select_field == "battery_type") {
        group = this.props.config.batteries[batteryType].parameters;
      }
      for (let filterIdx = 0; filterIdx < group.length; filterIdx++) {
        const filter = group[filterIdx];
        let val = filter.defaultValue;
        const converter = filter.conversion || ((e) => e);
        if (
          filtersState[groupIdx] &&
          filtersState[groupIdx][filterIdx] !== undefined
        ) {
          val = filtersState[groupIdx][filterIdx];
        }
        if (filter.section) {
          if (req[filter.section] === undefined) {
            req[filter.section] = {};
          }
          req[filter.section][filter.id] = converter(val);
        } else {
          // top level.
          // The only one right now is solar profile
          // and we're handling it separately, for now.
        }
      }
    }

    if (solarProfile.length) {
      req.Solar_Profile = solarProfile;
      req.Settings.Num_Period = solarProfile.length;
    }

    return req;
  }

  updateScenario() {
    // this is called as a callback from setState
    // We're changing state in places, props should never change
    // so we're ignoring props, and we're not going to use context.

    const { filtersState } = this.state;

    this.setState({
      appliedState: {
        filtersState,
      },
    });

    const request = this.filterToRequest(this.state);
    // If we're currently fetching, stash the request in the state
    // Then, when we're done with the inflight request, we'll
    // use the last one on the stack and run it one more time.
    // Ensures at most one inflight request at a time.
    const { fetching } = this.props.smoothing;
    if (fetching) {
      this.setState({ nextRequest: request });
    } else {
      this.doFetch(request);
    }
  }

  async doFetch(request) {
    try {
      await this.props.fetchSmoothing(request);
      const { hasError, getData } = this.props.smoothing;
      const { nextRequest } = this.state;
      if (!hasError()) {
        this.setState({ results: this.calculateResults(getData()) });
      }
      if (nextRequest) {
        this.setState({ nextRequest: false });
        this.doFetch(nextRequest);
      }
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log("error", error);
    }
  }

  calculateResults(results) {
    const Total_Demand = results.Demand.reduce((a, b) => a + b, 0);
    const Unserved_Energy = results.Unserved.reduce((a, b) => a + b, 0);
    const Solar_Plant_Cost =
      results.Solar_Capacity * results.input.General.Solar_Inv_Cost;
    const BESS_Energy_Cost =
      results.BESS_Energy * results.input.General.Cost_BESS_Energy;
    const BESS_Power_Cost =
      results.BESS_Power * results.input.General.Cost_BESS_Power;

    results.calculated = {
      Total_Demand,
      Total_Unserved_Energy: Unserved_Energy,
      USE_pct: (Unserved_Energy / Total_Demand) * 100.0,

      Solar_Plant_Cost,
      BESS_Energy_Cost,
      BESS_Power_Cost,
      Total_Infra_Cost: Solar_Plant_Cost + BESS_Energy_Cost + BESS_Power_Cost,
    };

    results.isSmoothing = results.input.Mode == "Smoothing";
    results.isShifting = results.input.Mode == "Defined_profile";
    results.renderedLocation = this.state.renderedLocation;

    return results;
  }

  render() {
    const { params, batteries } = this.props.config;
    const {
      location,
      solarProfile,
      batteryType,
      results,
      dashboardVisible,
      compatClass,
    } = this.state;

    return (
      <App
        pageTitle="Smoothing"
        hamburgerOnClick={this.toggleDashboard}
        className={compatClass}
      >
        <section className="inpage inpage--single inpage--horizontal inpage--explore">
          <header
            className={"inpage__header " + (dashboardVisible ? "show" : "")}
          >
            <Breakpoint>
              {({ largeDown, xsmallDown }) => {
                return !dashboardVisible && xsmallDown ? (
                  ""
                ) : (
                  <Dashboard
                    model={{ filters: params }}
                    onApplyClick={this.onApplyClick}
                    onResetClick={this.onResetClick}
                    handleFilterChange={this.handleFilterChange}
                    filtersState={this.state.filtersState}
                    mapBounds={bounds || []}
                    handleSelectedLocation={this.handleSelectedLocation}
                    handleSolarProfile={this.handleSolarProfile}
                    handleBatteryTypeChange={this.handleBatteryTypeChange}
                    batteryFilters={batteries[batteryType]}
                    location={location}
                    solarProfile={solarProfile}
                    showApply={largeDown}
                  />
                );
              }}
            </Breakpoint>
          </header>
          <Breakpoint>
            {({ xsmallDown }) => {
              return dashboardVisible && xsmallDown ? (
                ""
              ) : (
                <div className="inpage__body">
                  <Results results={results} />
                </div>
              );
            }}
          </Breakpoint>
        </section>
        <ReactTooltip
          id="econtrol-popover"
          effect="solid"
          type="light"
          className="popover"
          wrapper="article"
          globalEventOff="click"
          getContent={(dataTip) => this.popoverRenderFn(dataTip)}
        />
      </App>
    );
  }
}

if (environment !== "production") {
  Smoothing.propTypes = {
    fetchSmoothing: T.func,
    fetchAltitude: T.func,
    fetchSolarProfile: T.func,
    match: T.object,
    history: T.object,
    location: T.object,
    smoothing: T.object,
  };
}

function mapStateToProps(state) {
  return {
    smoothing: wrapApiResult(state.smoothing),
    altitude: wrapApiResult(state.altitude),
    solarProfile: wrapApiResult(state.solarProfile),
  };
}

function dispatcher(dispatch) {
  return {
    fetchSmoothing: (...args) => dispatch(fetchSmoothing(...args)),
    fetchAltitude: (...args) => dispatch(fetchAltitude(...args)),
    fetchSolarProfile: (...args) => dispatch(fetchSolarProfile(...args)),
  };
}

export default connect(mapStateToProps, dispatcher)(Smoothing);
