import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { dataServiceUrl, environment } from "../../config";
import Dropzone from "react-dropzone";
import { parse as parseCallback } from "csv-parse";
import duckdb from "../../duckdb";

const readFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onabort = () => reject("Aborted");
    reader.onerror = () => reject(reader.error);
    reader.onload = () => resolve(reader.result);
    reader.readAsBinaryString(file);
  });

const parsePromise = (text, options) =>
  new Promise((resolve, reject) =>
    parseCallback(text, options, (err, data) => {
      if (err) {
        return reject(err);
      }
      resolve(data);
    })
  );

const parse = (text) =>
  parsePromise(text, {
    bom: true,
    cast: (value, context) => {
      if (context.index === 0 && !context.header) {
        return parseFloat(value) || 0; // sometimes the BOM is not encoded properly.
      }
      return value;
    },
  });

const serverReduce = async (text) => {
  const req = await fetch(`${dataServiceUrl}/reduce`, {
    method: "POST",
    mode: "cors",
    headers: { "Content-Type": "text/csv" },
    body: text,
  });
  return await req.text();
};

const clientReduce = async (text) => {
  const db = await duckdb.getDB();
  await db.registerFileText("input.csv", text);
  const conn = await db.connect();
  await conn.send(`create table input as select
      date_part('year', "Date") as year,
      "Date",
      split_part("Time",':', 1)::tinyint as hour,
      split_part("Time",':', 2)::tinyint as minute,
      "PVOUT"
    from "input.csv"`);
  await conn.send(`create table data as (select PVOUT*1000 as PVOUT from (
        select distinct
          "Date",
          hour,
          first_value(PVOUT) over (PARTITION by "Date", hour order by minute) as PVOUT
        from input
        where year = (SELECT max(year) from input)
        order by "Date", hour
    ))`);
  const data = [
    ...(await conn.query("select PVOUT from data")).getChild("PVOUT").toArray(),
  ];
  await conn.close();
  await db.reset();
  return data;
};

class CSVUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: this.props.errorMessage,
    };

    this.handleFiles = this.handleFiles.bind(this);

    duckdb.getDB(); // pre-initialize duckdb
  }

  async handleFiles([file]) {
    // convert csv-> array
    // send upstream
    if (!file) {
      this.setState({ error: "That does not appear to be a CSV file" });
      return;
    }

    try {
      let result = await readFile(file);
      let data;

      // Do we have a single column of numbers?
      if (!/^([\d.]+,*\r?\n){4}/m.test(result)) {
        if ((await duckdb.getDB()) !== undefined) {
          data = await clientReduce(result);
        } else {
          result = await serverReduce(result);
        }
      }

      if (!data) {
        data = await parse(result);
        data = data.map(([e]) => e);
      }

      this.props.handleUpload(data);
    } catch (error) {
      console.error(error);
      this.setState({ error: error.toString() });
    }
  }

  render() {
    const { error } = this.state;
    const error_class = error ? "input__error" : "";
    return (
      <div>
        <div className={"dropzone__container " + error_class}>
          <button
            className="econtrol__cancel"
            onClick={this.props.cancelUpload}
            title="Cancel"
          />
          <Dropzone
            onDrop={this.handleFiles}
            accept={{
              "text/csv": [".csv"],
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone__content">
                <div {...getRootProps()}>
                  {this.props.title || ""}
                  <div>{this.props.description || ""}</div>
                  <input {...getInputProps()} />
                  <p>Drag and drop a CSV here, or click to select a file</p>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        <div className="dropzone__error_msg">{error}</div>
      </div>
    );
  }
}

if (environment !== "production") {
  CSVUpload.propTypes = {
    handleUpload: T.func,
    cancelUpload: T.func,
    title: T.string,
    description: T.string,
    errorMessage: T.string,
  };
}

export default CSVUpload;
