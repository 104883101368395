const solar_profile_2 = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 519.6033169967886, 686.7933343723905,
  765.9721362980212, 852.8830853775319, 860.9735761658278, 856.7743944216844,
  826.3454680569706, 773.0279729306087, 684.3101643986902, 511.9569704314307,
  116.7937799337479, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 703.5244491342127, 555.6395641760978, 668.6288450729039,
  745.0115572314007, 769.6161377634921, 758.4772025359379, 719.8568435140788,
  651.342550032409, 526.8963670211725, 295.2640511532935, 49.23686960718205,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 5.294387001457818,
  569.9974795224496, 525.8219193376106, 625.8610112184313, 701.3270063360541,
  722.3990863517948, 697.249087180536, 654.8903488497727, 585.3065829970991,
  471.17741492473226, 292.3181204720983, 70.54424153016633, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 310.5895503913849, 290.71762938030724,
  466.08898522616994, 600.7353440846591, 630.5939488903724, 614.8318760695065,
  586.0180196063867, 563.4322764512872, 521.3546584213291, 411.1594484542799,
  249.38344821442635, 53.14538923997322, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 366.55676957504886, 370.04683469052395,
  326.27894506667906, 446.1761827582081, 486.8300157280426, 491.7332670074122,
  422.7793716869023, 405.22356185657884, 333.55231142663746, 235.1087538310594,
  83.19124121240263, 8.548665931004267, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 187.2573834751731, 279.7927053349813, 356.44454127222116,
  473.0559669467641, 517.4341976982283, 516.2892407018985, 514.1002768974738,
  479.63495696403425, 407.1633101700761, 305.90640174779065, 167.8930486516056,
  33.9013709399985, 0.28027985190330085, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 76.19092260768248, 356.3303488418952, 303.9238540819012,
  419.6148440984672, 515.9340800641054, 387.5446029462589, 397.4369060935202,
  487.35794170212614, 299.18614746744487, 81.28192576311234, 43.10349024307673,
  31.97424543493229, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  38.17177335669154, 380.72900796153345, 191.5528421317987, 316.00558636451046,
  505.8802699266845, 558.6779144284708, 402.0126010084728, 98.70701587327554,
  26.564518328609783, 348.67996460178364, 254.70964592427586, 61.72342925481271,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 82.2564334319682,
  267.50030776393805, 293.0364989073472, 392.16467278451324, 440.4217007679664,
  442.4185613445991, 435.2972057587688, 396.49988850905345, 333.08986051802805,
  263.22226737914417, 154.75586795319185, 42.807007423090525,
  0.3426993722767215, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  39.0572859011747, 329.522207312817, 513.8647717526883, 694.1772320787392,
  754.4950458098212, 746.5711091051272, 721.1943232763363, 596.7178577044447,
  95.80847010925912, 15.379856434466914, 8.979233472582258, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 18.301164589908932,
  454.01886125847824, 433.96776843019256, 522.9444928557105, 600.7202028043315,
  620.6915515562263, 602.6633337796865, 538.8428371995233, 402.67982676156885,
  238.1960608898246, 60.368789374851005, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 34.73357480669748, 471.734792322653,
  471.8068445189183, 604.8515889172123, 684.7047867602096, 684.7206467027557,
  643.5687424137441, 582.3779532280096, 476.76755805432487, 320.6140144234231,
  143.7878269804419, 9.717039793729203, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
];

const solar_profile_1 = [
  0, 0, 0, 0, 0, 0, 9.693792150971728, 187.43506487115008, 406.5395496055662,
  570.2862083313358, 678.4488043314615, 726.874639390683, 728.5297172664991,
  678.1557710519912, 572.3792264799453, 416.2979235842248, 224.529780992233,
  28.34686894977702, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12.031456167085599,
  173.61970378525334, 380.2570040095521, 545.4560255084542, 658.8894643756953,
  712.9226629123173, 717.4216252175573, 669.282881904186, 566.8193056570531,
  416.69225260897315, 234.83416895100044, 35.934066804015416, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 21.35982979314702, 182.54930257126, 364.8122505731874,
  518.1775441441921, 623.525356309885, 675.128889825025, 678.9757451047855,
  626.1513042851182, 520.4111334147894, 372.94454465150403, 203.18615552288543,
  29.514468431867584, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 42.063718389972934,
  191.75702350084694, 351.2111231608678, 486.07527333952635, 576.955251344177,
  616.195228895934, 608.8315965341218, 544.6355722719932, 438.04628394837573,
  305.2373229444466, 160.06173049981783, 27.4834367432619, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0.7767041148743947, 56.71297352849274, 184.38904773691536,
  326.706062117352, 443.2563457963759, 536.1033902183361, 570.3936348749432,
  565.1024374173559, 503.94211491645694, 404.779757300162, 280.0394264456713,
  150.4734616613823, 27.404614976754832, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0.3322286907865931, 49.733461745094026, 158.00102424144478, 281.2751100969041,
  399.9216699056751, 494.568333100949, 512.0666646666753, 514.9898273433051,
  477.3251222128072, 399.1679039275732, 286.8250915460139, 159.15758542123194,
  42.34357338886896, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 41.19228786869435,
  148.52682997029527, 271.80331922120195, 378.9440036729444, 482.82268695348444,
  499.6368766178151, 502.62021941885064, 472.21137888009525, 396.17476061207026,
  287.6200795518518, 170.39664519948062, 50.50716096468045, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 34.84345185830075, 148.9462564882656, 275.38411759245685,
  390.8706215577085, 459.2809086414963, 508.70171211892205, 518.0076786378445,
  487.3198436104052, 406.9293604329316, 296.46640786118286, 170.74204867953392,
  40.64967557531475, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 46.58636236609942,
  187.98837805944413, 336.8305064044964, 461.0755219949624, 541.5234425962257,
  588.9871330400159, 598.1703521205127, 557.4947374254253, 451.61970682504113,
  310.23284628262735, 157.81558106428912, 22.170877600248424, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 65.29191525307313, 249.27302930575985, 425.0544368143408,
  562.119042043294, 640.0623337561973, 676.0734182059822, 667.2715621190227,
  596.5706246327811, 476.2621713039339, 318.27545696614135, 133.4052494491998,
  5.465816522342503, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 61.59638886684465,
  294.18854632926457, 483.63289841835535, 619.627355950016, 701.2482361928089,
  729.012782384896, 709.0264336971162, 636.6446175186207, 514.9749277424185,
  348.79900810937204, 140.11461586794755, 2.532878505792908, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 34.25881508723259, 256.6345657425554, 456.8457868058614,
  605.6805943775402, 698.6373701020822, 735.2676684962653, 722.9251850902795,
  660.328799424883, 546.5630092603099, 385.3540713142148, 167.59550410786517,
  4.27334164927783, 0, 0, 0, 0, 0, 0,
];

export { solar_profile_1, solar_profile_2 };
