import { fetchDispatchCacheFactory, fetchDispatchFactory } from "./utils";
import { dataServiceUrl } from "../config";

/*
 * Actions for Smoothing
 */

export const REQUEST_SMOOTHING = "REQUEST_SMOOTHING";
export const RECEIVE_SMOOTHING = "RECEIVE_SMOOTHING";
export const INVALIDATE_SMOOTHING = "INVALIDATE_SMOOTHING";

export function invalidateSmoothing() {
  return { type: INVALIDATE_SMOOTHING };
}

export function requestSmoothing() {
  return { type: REQUEST_SMOOTHING };
}

export function receiveSmoothing(data, error = null) {
  return {
    type: RECEIVE_SMOOTHING,
    data,
    error,
    receivedAt: Date.now(),
  };
}

export function fetchSmoothing(filters) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  return fetchDispatchFactory({
    statePath: ["smoothing"],
    url: `${dataServiceUrl}/smooth`,
    options: {
      method: "POST",
      mode: "cors",
      headers,
      body: JSON.stringify(filters),
    },
    requestFn: requestSmoothing,
    receiveFn: receiveSmoothing,
  });
}

/*
 * Actions for GSA
 */

export const REQUEST_ALTITUDE = "REQUEST_ALTITUDE";
export const RECEIVE_ALTITUDE = "RECEIVE_ALTITUDE";
export const INVALIDATE_ALTITUDE = "INVALIDATE_ALTITUDE";

export function invalidateAltitude() {
  return { type: INVALIDATE_ALTITUDE };
}

export function requestAltitude() {
  return { type: REQUEST_ALTITUDE };
}

export function receiveAltitude(data, error = null) {
  return {
    type: RECEIVE_ALTITUDE,
    data,
    error: error || data === undefined,
    receivedAt: Date.now(),
  };
}

export function fetchAltitude(loc) {
  const [lon, lat] = loc;
  const param_loc = [lat, lon].join(",");
  return fetchDispatchFactory({
    statePath: ["altitude"],
    url: `https://api.globalsolaratlas.info/data/lta?loc=${param_loc}`,
    mutator: (res) => res.annual.data.OPTA,
    requestFn: requestAltitude,
    receiveFn: receiveAltitude,
  });
}

/*
 * Actions for GSA
 */

export const REQUEST_SOLARPROFILE = "REQUEST_SOLARPROFILE";
export const RECEIVE_SOLARPROFILE = "RECEIVE_SOLARPROFILE";
export const INVALIDATE_SOLARPROFILE = "INVALIDATE_SOLARPROFILE";

export function invalidateSolarProfile() {
  return { type: INVALIDATE_SOLARPROFILE };
}

export function requestSolarProfile() {
  return { type: REQUEST_SOLARPROFILE };
}

export function receiveSolarProfile(data, error = null) {
  return {
    type: RECEIVE_SOLARPROFILE,
    data,
    error,
    receivedAt: Date.now(),
  };
}

export function fetchSolarProfile(loc, tilt) {
  const [lon, lat] = loc;
  const param_loc = [lat, lon].join(",");
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  return fetchDispatchFactory({
    statePath: ["solarProfile"],
    url: `https://api.globalsolaratlas.info/data/pvcalc?loc=${param_loc}`,
    options: {
      method: "POST",
      mode: "cors",
      headers,
      body: JSON.stringify({
        type: "rooftopLargeFlat",
        systemSize: {
          type: "capacity",
          value: 1,
        },
        orientation: {
          azimuth: 180,
          tilt,
        },
      }),
    },
    mutator: (res) => res["monthly-hourly"].data.PVOUT_specific.flat(),
    requestFn: requestSolarProfile,
    receiveFn: receiveSolarProfile,
  });
}

/*
 * Actions form about pages
 */

export const REQUEST_ABOUT_PAGE = "REQUEST_ABOUT_PAGE";
export const RECEIVE_ABOUT_PAGE = "RECEIVE_ABOUT_PAGE";
export const INVALIDATE_ABOUT_PAGE = "INVALIDATE_ABOUT_PAGE";

export function invalidateAboutPage(key) {
  return { type: INVALIDATE_ABOUT_PAGE, id: key };
}

export function requestAboutPage(key) {
  return { type: REQUEST_ABOUT_PAGE, id: key };
}

export function receiveAboutPage(key, data, error = null) {
  return {
    type: RECEIVE_ABOUT_PAGE,
    id: key,
    data,
    error,
    receivedAt: Date.now(),
  };
}

export function fetchAboutPage(url) {
  return fetchDispatchCacheFactory({
    type: "md",
    statePath: ["individualAboutPages", url],
    url,
    receiveFn: receiveAboutPage.bind(this, url),
    requestFn: requestAboutPage.bind(this, url),
  });
}
