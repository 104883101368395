import { Component, Fragment } from "react";
import { PropTypes as T } from "prop-types";
import { Group } from "@visx/group";
import { GridColumns } from "@visx/grid";
import { AreaStack, LinePath, AreaClosed } from "@visx/shape";
import { scaleLinear, scaleOrdinal } from "@visx/scale";
import { LegendOrdinal, LegendItem, LegendLabel } from "@visx/legend";
import { AxisBottom } from "@visx/axis";
import { ParentSize } from "@visx/responsive";
import YAxis from "./YAxis";

import { environment } from "../../config";

import Breakpoint from "../Breakpoint";

/**
 * The Chart component
 */

const tickComponent = ({ formattedValue, ...tickProps }) => (
  <text {...tickProps}>{formattedValue}</text>
);

const legendMarkerWidth = 18;
const legendMarkerHeight = 12;

const seriesLabels = {
  BESS_Out: "BESS Discharge per hour",
  BESS_In: "BESS Charge per hour",
  BESS_SOC: "BESS Stored Energy",
  Unserved: "Unserved Energy",
  solar_injected: "Load Served from Solar",
  bess_injected: "Load Served from BESS",
  unserved: "Unserved Energy",
  excess_solar: "Solar Output",
  solar_profile: "Solar Output",
  injected: "Served Energy",
};

// Percentages
const heights = {
  bess: 0.3,
  main: 0.4,
  unserved: 0.3,
};

class ResultChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: [0, this.props.results.Gen_From_PV.length],
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    };

    this.renderChart = this.renderChart.bind(this);
    this.renderBess = this.renderBess.bind(this);
    this.genProperties = this.genProperties.bind(this);
    this.renderUnserved = this.renderUnserved.bind(this);
    this.renderLegend = this.renderLegend.bind(this);
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize, false);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize, false);
  }

  resize() {
    if (/iPhone/.test(window.navigator.platform)) {
      return;
    }
    this.setState({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    });
  }

  renderLegend(ordinalColorScale, stroke, strokeDasharray, opacity, symbol) {
    strokeDasharray = strokeDasharray || {};
    symbol = symbol || {};
    return (
      <LegendOrdinal scale={ordinalColorScale}>
        {(labels) => (
          <div className="chart__legend">
            {labels.map((label, i) => (
              <LegendItem
                key={`legend-item-${i}`}
                className="chart__legend_item"
                margin="0"
              >
                <svg width={legendMarkerWidth} height={legendMarkerHeight}>
                  {symbol[label.text] == "line" ? (
                    <line
                      stroke={
                        stroke[label.text] == "transparent"
                          ? label.value
                          : stroke[label.text]
                      }
                      strokeWidth={3}
                      x={0}
                      x2={legendMarkerWidth}
                      y1={legendMarkerHeight / 2}
                      y2={legendMarkerHeight / 2}
                      strokeDasharray={strokeDasharray[label.text] || undefined}
                    />
                  ) : (
                    <rect
                      fill={label.value}
                      fillOpacity={(opacity && opacity[label.text]) || 1}
                      stroke={
                        stroke[label.text] == "transparent"
                          ? label.value
                          : stroke[label.text]
                      }
                      strokeWidth={2}
                      width={legendMarkerWidth - legendMarkerHeight / 2}
                      height={legendMarkerHeight / 2}
                      y={legendMarkerHeight / 4}
                      x={legendMarkerHeight / 4}
                      ry={legendMarkerHeight / 4}
                      strokeDasharray={strokeDasharray[label.text] || undefined}
                    />
                  )}
                </svg>
                <LegendLabel className="chart__legend_label" margin="3px 0">
                  {seriesLabels[label.text]}
                </LegendLabel>
              </LegendItem>
            ))}
          </div>
        )}
      </LegendOrdinal>
    );
  }

  renderBess(data, properties, height) {
    // Chart properties
    const margin = {
      top: 18,
      bottom: 0,
    };

    const { width, xMin, xMax, xScale } = properties;

    const yMin = height - margin.top - margin.bottom;
    const yMax = margin.top;

    const parsedKeys = ["BESS_In", "BESS_Out"];
    // insert 0's at start and end, to ensure areas start at the baseline
    // The BESS_Out is inverted so that we're displaying it below the x axis
    const parsedData = [
      {
        step: 0,
        BESS_In: 0,
        BESS_Out: 0,
        BESS_SOC: data[0].BESS_SOC,
      },
      ...data.map((d) => {
        return {
          step: d.step,
          BESS_In: d.BESS_In,
          BESS_Out: 0 - d.BESS_Out,
          BESS_SOC: d.BESS_SOC,
        };
      }),
      {
        step: data.length - 1,
        BESS_In: 0,
        BESS_Out: 0,
        BESS_SOC: data[data.length - 1].BESS_SOC,
      },
    ];

    const yScale = scaleLinear({
      range: [margin.top, yMin],
      domain: [
        Math.max(...parsedData.map((d) => d.BESS_SOC)),
        Math.min(...parsedData.map((d) => d.BESS_Out)),
      ],
    });

    const colors = {
      BESS_Out: "#307FB8",
      BESS_In: "#89D9B2",
      BESS_SOC: "transparent",
    };

    const stroke = {
      BESS_Out: "#014A95",
      BESS_In: "#6EC99E",
      BESS_SOC: "#049FD9",
    };

    const ordinalColorScale = scaleOrdinal({
      domain: Object.keys(colors),
      range: Object.keys(colors).map((e) => colors[e]),
    });

    return (
      <figure className="sum-chart-media">
        <div className="sum-area-chart-media__item">
          <svg width={width} height={height} id="results__bess">
            <Group>
              <GridColumns
                lineStyle={{ pointerEvents: "none" }}
                scale={xScale}
                height={yMax - yMin}
                top={yMin}
                yscale={yScale}
                strokeDasharray="2,2"
                stroke="#eee"
                tickValues={this.xTicks(data)}
              />
              <YAxis xMin={xMin} yScale={yScale} label="MWh" />
              {parsedKeys.map((key) => {
                return (
                  <AreaClosed
                    yScale={yScale}
                    data={parsedData}
                    x0={(d) => xScale(d.step)}
                    y0={() => yScale(0)}
                    x1={(d) => xScale(d.step)}
                    y1={(d) => yScale(d[key])}
                    strokeWidth={2}
                    stroke={stroke[key]}
                    fill={colors[key]}
                    fillOpacity={0.8}
                    key={"area-" + key}
                  />
                );
              })}

              <LinePath
                data={parsedData}
                x={(d) => xScale(d.step)}
                y={(d) => yScale(d.BESS_SOC)}
                strokeWidth={2}
                stroke={stroke.BESS_SOC}
              />
            </Group>
            <rect width={xMax - xMin} x={xMin} height={height} opacity={0} />
          </svg>
          {this.renderLegend(
            ordinalColorScale,
            stroke,
            {},
            {},
            { BESS_SOC: "line" }
          )}
        </div>
      </figure>
    );
  }

  renderChart(data, properties, height) {
    // Chart properties
    const { width, xMin, xMax, xScale } = properties;

    const margin = {
      top: 18,
      bottom: 0,
    };
    const yMin = height - margin.top - margin.bottom;
    const yMax = margin.top;

    const skipUnserved = !(Math.max(...data.map((d) => d.Unserved)) > 0);

    let parsedKeys;
    if (skipUnserved) {
      parsedKeys = ["solar_injected", "bess_injected", "excess_solar"];
    } else {
      parsedKeys = [
        "solar_injected",
        "bess_injected",
        "unserved",
        "excess_solar",
      ];
    }

    // Rewriting the data so that we're showing some derived data rather than the raw traces
    const parsedData = data.map((d) => {
      const solar_injected = Math.min(d.Injected, d.Gen_From_PV);
      return {
        step: d.step,
        unserved: d.Unserved,
        solar_injected,
        bess_injected: d.Injected - solar_injected,
        excess_solar: Math.max(d.Gen_From_PV - d.Injected - d.Unserved, 0),
        gen_from_pv: d.Gen_From_PV,
        injected: d.Injected,
      };
    });

    const yScale = scaleLinear({
      range: [margin.top, yMin],
      domain: [
        Math.max(
          ...data.map((d) => d.Gen_From_PV),
          ...data.map((d) => d.Demand)
        ),
        0,
      ],
    });

    const colors = {
      solar_injected: "#4cbb88",
      bess_injected: "#0071bd",
      unserved: "#F9BDBF",
      excess_solar: "#f9f5e1",
      injected: "transparent",
    };
    const stroke = {
      solar_injected: "transparent",
      bess_injected: "transparent",
      unserved: "#ff070c",
      excess_solar: "transparent",
      injected: "#111111",
      solar_profile: "#efd04d",
    };

    const fillOpacity = {
      solar_injected: 0.8,
      bess_injected: 0.8,
      unserved: 1.0,
      excess_solar: 1.0,
    };

    const ordinalColorScale = scaleOrdinal({
      domain: Object.keys(colors)
        .map((e) => e.replace("excess_solar", "solar_profile"))
        .filter((e) => (skipUnserved ? e != "unserved" : true)),
      range: Object.keys(colors)
        .map((e) => colors[e])
        .filter((e) => (skipUnserved ? e != "unserved" : true)),
    });

    return (
      <figure className="sum-chart-media">
        <div className="sum-area-chart-media__item">
          <svg width={width} height={height} id="results__injected">
            <Group>
              <GridColumns
                lineStyle={{ pointerEvents: "none" }}
                scale={xScale}
                height={yMax - yMin}
                top={yMin}
                yScale
                strokeDasharray="2,2"
                stroke="#eee"
                tickValues={this.xTicks(data)}
              />
              <YAxis xMin={xMin} yScale={yScale} label="MW" />
              <AreaStack
                top={margin.top}
                keys={parsedKeys}
                data={parsedData}
                x={(d) => {
                  return xScale(d.data.step);
                }}
                y0={(d) => yScale(d[0])}
                y1={(d) => yScale(d[1])}
              >
                {(area) => {
                  const { stacks, path } = area;
                  return stacks.map((stack) => {
                    return (
                      <path
                        key={`stack-${stack.key}`}
                        d={path(stack)}
                        stroke={stroke[stack.key]}
                        strokeWidth={2}
                        fill={colors[stack.key]}
                        fillOpacity={fillOpacity[stack.key]}
                      />
                    );
                  });
                }}
              </AreaStack>
              <LinePath
                data={parsedData}
                x={(d) => xScale(d.step)}
                y={(d) => yScale(d.gen_from_pv)}
                strokeWidth={2}
                stroke={stroke.solar_profile}
                //                strokeDasharray="3,1"
              />
              <LinePath
                data={parsedData}
                x={(d) => xScale(d.step)}
                y={(d) => yScale(d.injected)}
                strokeWidth={2}
                stroke={stroke.injected}
                strokeDasharray="2,2"
              />
            </Group>
            <rect width={xMax - xMin} x={xMin} height={height} opacity={0} />
          </svg>
          {this.renderLegend(
            ordinalColorScale,
            stroke,
            { injected: "2,2" },
            fillOpacity,
            { injected: "line" }
          )}
        </div>
      </figure>
    );
  }

  xTicks(data) {
    const days = Array.from(Array(data.length / 24).keys()).map((e) => e * 24);
    days.push(data.length);
    return days;
  }

  renderUnserved(data, properties, height) {
    // Chart properties
    const { width, xMin, xMax, xTickLength, xScale } = properties;

    const margin = {
      top: 18,
      bottom: 10,
    };
    const yMin = height - margin.top - margin.bottom;
    const yMax = margin.top;

    // Rewriting the data so that we're showing some derived data rather than the raw traces
    const parsedData = data;

    const yScale = scaleLinear({
      range: [margin.top, yMin],
      domain: [Math.max(1, ...data.map((d) => d.Unserved)), 0],
    });

    const colors = { Unserved: "#F9BDBF" };
    const stroke = { Unserved: "#ff070c" };

    const days = this.xTicks(data);
    const xLabelOffset = xScale(12) - xScale(0);
    const xTickProps = () => ({
      textAnchor: "middle",
      dx: -xLabelOffset,
      dy: "-0.1em",
    });

    const ordinalColorScale = scaleOrdinal({
      domain: Object.keys(stroke),
      range: Object.keys(stroke).map((e) => colors[e]),
    });

    return (
      <figure className="sum-chart-media">
        <div className="sum-area-chart-media__item">
          <svg width={width} height={height} id="results__unserved">
            <Group>
              <GridColumns
                lineStyle={{ pointerEvents: "none" }}
                scale={xScale}
                height={yMax - yMin}
                top={yMin}
                yScale
                strokeDasharray="2,2"
                stroke="#eee"
                tickValues={days}
              />
              <YAxis xMin={xMin} yScale={yScale} label="MW" />

              <AreaClosed
                yScale={yScale}
                data={parsedData}
                x0={(d) => xScale(d.step)}
                y0={() => yScale(0)}
                x1={(d) => xScale(d.step)}
                y1={(d) => yScale(d.Unserved)}
                fill={colors.Unserved}
                fillOpacity={1}
                key={"area-unserved"}
              />
              <LinePath
                data={parsedData}
                x={(d) => xScale(d.step)}
                y={(d) => yScale(d.Unserved)}
                strokeWidth={2}
                stroke={stroke.Unserved}
              />
              <AxisBottom
                top={yMin}
                scale={xScale}
                tickValues={days}
                tickLength={xTickLength}
                tickComponent={tickComponent}
                tickLabelProps={xTickProps}
                tickFormat={(d) => d / 24}
                hideZero={true}
                className="x-axis"
                numTicks={days.length}
              />
            </Group>
            <rect width={xMax - xMin} x={xMin} height={height} opacity={0} />
          </svg>
          {this.renderLegend(ordinalColorScale, stroke)}
        </div>
        <figcaption className="sum-chart-media__caption">
          {"Representative Days"}
        </figcaption>
      </figure>
    );
  }

  genProperties(data, parentWidth) {
    // Chart properties
    const width = parentWidth;

    const xMin = 35; // left margin
    const xMax = width - xMin;
    const xScale = scaleLinear({
      range: [xMin, xMax],
      domain: this.state.domain,
    });

    return {
      width: parentWidth,
      xMin,
      xMax,
      xTickLength: 6,
      yTickLength: 4,
      xScale,
    };
  }

  render() {
    const { results } = this.props;

    const keys = [
      "Gen_From_PV",
      "BESS_In",
      "BESS_Out",
      "BESS_SOC",
      "Injected",
      "Demand",
      "Unserved",
    ];

    // need to convert from { :[], :[] } to [ { step:n, trace1:, trace2:} ]
    const data = Object.keys(results.Gen_From_PV).map((elt) => {
      return {
        step: parseInt(elt),
        ...keys.reduce((acc, trace) => {
          acc[trace] = results[trace][elt];
          return acc;
        }, {}),
      };
    });

    // Force a redraw of the graphs on resizing of the window
    // Otherwise there's a slow adjust of the size of the graphs 30 px at a time
    // doing this with a key set from window size stored in state on the parent element
    // Putting it in state forces a refresh when the window size changes.
    const { screenWidth, screenHeight } = this.state;

    return (
      <Breakpoint>
        {({ xsmallDown }) => (
          <ParentSize
            key={`chart-parent-size-${screenWidth}-${screenHeight}`}
            className="inpage__results_chart"
            parentSizeStyles={{}}
          >
            {({ width: parentWidth, height: parentHeight }) => {
              const width = Math.min(parentWidth - 8, screenWidth);
              const properties = this.genProperties(data, width);
              let offset = screenWidth < 1270 ? 110 : 100;
              if (xsmallDown) {
                offset = 150;
              }
              if (screenWidth <= 375) {
                offset = 175;
              }
              const height =
                Math.min(screenHeight, Math.max(400, parentHeight)) - offset;
              return (
                (parentWidth && (
                  <Fragment>
                    {this.renderChart(data, properties, heights.main * height)}
                    {this.renderBess(data, properties, heights.bess * height)}
                    {this.renderUnserved(
                      data,
                      properties,
                      heights.unserved * height
                    )}
                  </Fragment>
                )) ||
                ""
              );
            }}
          </ParentSize>
        )}
      </Breakpoint>
    );
  }
}

if (environment !== "production") {
  ResultChart.propTypes = {
    appliedState: T.object,
    scenario: T.object,
    techLayers: T.array,
  };
}

export default ResultChart;
