import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { environment } from "../../config";
import Map from "./Map";
import ProfileChart from "./ProfileChart";
import CSVUpload from "./CSVUpload";

class SolarProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showingMap: true,
      showingUpload: false,
    };

    this.onUploadSolarProfile = this.onUploadSolarProfile.bind(this);
    this.onSetProfileFromMap = this.onSetProfileFromMap.bind(this);
    this.renderProfile = this.renderProfile.bind(this);
    this.handleSolarProfile = this.handleSolarProfile.bind(this);
  }

  handleSolarProfile(e) {
    this.setState({ showingUpload: false });
    return this.props.handleSolarProfile(e);
  }

  onUploadSolarProfile(e) {
    e.preventDefault();
    this.setState({
      showingMap: false,
      showingUpload: true,
    });
  }

  onSetProfileFromMap(e) {
    e.preventDefault();
    this.setState({ showingMap: true });
  }

  renderProfile() {
    const { solarProfile, description, filterKey, label } = this.props;
    const { showingUpload } = this.state;
    return (
      <div className="solarprofile__container">
        <div className="form__group econtrols__item">
          <div className="form__inner-header">
            <div className="form__inner-headline">
              <label className="form__label" htmlFor={filterKey}>
                {label}
              </label>
            </div>
            {description && (
              <div className="form__inner-actions">
                <button
                  type="button"
                  className="eci-info"
                  data-tip={filterKey}
                  data-for="econtrol-popover"
                  data-event="click"
                >
                  <span>Parameter info</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="solarprofile__profilecontainer">
          <ProfileChart dataSeries={solarProfile} />
        </div>
        {(showingUpload && (
          <CSVUpload
            handleUpload={this.handleSolarProfile}
            cancelUpload={() => this.setState({ showingUpload: false })}
            title="Upload a CSV of a Solar Profile"
            description="The Profile should be either one column and a multiple of 24 rows (e.g. 1x288) or in the SolarGIS format"
          />
        )) || (
          <div className="solarprofile__buttons">
            <button
              title="Set Profile from Location"
              className="econtrols__map"
              type="submit"
              onClick={this.onSetProfileFromMap}
            >
              <span>Set From Location</span>
            </button>
            <button
              title="Update Solar Profile"
              className="econtrols__upload"
              type="submit"
              onClick={this.onUploadSolarProfile}
            >
              <span>Update Profile</span>
            </button>
          </div>
        )}
      </div>
    );
  }

  renderMap() {
    const { description, filterKey, label } = this.props;
    return (
      <div className="solarprofile__container">
        <div className="form__group econtrols__item">
          <div className="form__inner-header">
            <div className="form__inner-headline">
              <label className="form__label" htmlFor={filterKey}>
                {label}
              </label>
            </div>
            {description && (
              <div className="form__inner-actions">
                <button
                  type="button"
                  className="eci-info"
                  data-tip={filterKey}
                  data-for="econtrol-popover"
                  data-event="click"
                >
                  <span>Parameter info</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="solarprofile__mapcontainer">
          <Map
            bounds={this.props.mapBounds || []}
            layersState={[]}
            externalLayers={[]}
            handleSelectedLocation={this.props.handleSelectedLocation}
          />
        </div>
        <div className="solarprofile__buttons">
          <div />
          <button
            title="Upload Solar Profile"
            className="econtrols__upload"
            type="submit"
            onClick={this.onUploadSolarProfile}
          >
            <span>Upload Solar Profile</span>
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { showingMap } = this.state;
    if (showingMap) {
      return this.renderMap();
    }
    return this.renderProfile();
  }
}

if (environment !== "production") {
  SolarProfile.propTypes = {
    handleSelectedLocation: T.func,
    handleSolarProfile: T.func,
    location: T.array,
    solarProfile: T.array,
    mapBounds: T.array,
    description: T.string,
    filterKey: T.string,
    label: T.string,
  };
}

export default SolarProfile;
