import React, { createRef } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { PropTypes as T } from "prop-types";

import { mapboxAccessToken, environment, basemapStyleLink } from "../../config";
import MapboxControl from "../MapboxReactControl";
import LayerControlDropdown from "./MapLayerControl";

mapboxgl.accessToken = mapboxAccessToken;

/**
 * Id of the last "topmost" layer, before which all GEP layers
 * should be added. This is needed to show place names and borders above
 * all other layers.
 **/
const labelsAndBordersLayer = "admin-2-boundaries-bg";

// Adds layers for points
const buildLayersForSource = (sourceId, sourceLayer) => [
  {
    id: `${sourceId}-line`,
    type: "line",
    source: sourceId,
    "source-layer": sourceLayer,
    filter: ["==", "$type", "LineString"],
    layout: {
      visibility: "none",
    },
    paint: {
      "line-color": "red",
    },
  },
  {
    id: `${sourceId}-polygon`,
    type: "fill",
    source: sourceId,
    "source-layer": sourceLayer,
    filter: ["==", "$type", "Polygon"],
    layout: {
      visibility: "none",
    },
    paint: {
      "fill-color": "blue",
    },
  },
  {
    id: `${sourceId}-point`,
    type: "circle",
    source: sourceId,
    "source-layer": sourceLayer,
    filter: ["==", "$type", "Point"],
    paint: {
      "circle-color": "purple",
    },
  },
];

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.clearMap = this.clearMap.bind(this);
    this.state = {
      mapLoaded: false,
    };
    this.mapEl = createRef();
  }

  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prevProps) {
    // Quick and dirty diffing.
    const prevLState = prevProps.layersState.join("");
    const lState = this.props.layersState.join("");
    if (prevLState !== lState) {
      this.toggleExternalLayers();
    }

    // Manually render detached component.
    this.layerDropdownControl &&
      this.layerDropdownControl.render(this.props, this.state);
  }

  componentWillUnmount() {
    if (this.map) {
      this.map.remove();
    }
  }

  initMap() {
    if (!mapboxgl.supported()) {
      return;
    }

    const { bounds, externalLayers } = this.props;

    this.map = new mapboxgl.Map({
      container: this.mapEl.current,
      style: basemapStyleLink,
      bounds,
      preserveDrawingBuffer: true,
    });

    // Disable map rotation using right click + drag.
    this.map.dragRotate.disable();

    // disable map zoom when using scroll, interacts badly with being in a scroll element
    this.map.scrollZoom.disable();

    // Disable map rotation using touch rotation gesture.
    this.map.touchZoomRotate.disableRotation();

    // Add zoom controls.
    this.map.addControl(new mapboxgl.NavigationControl(), "bottom-left");

    if (this.props.externalLayers && this.props.externalLayers.length) {
      this.layerDropdownControl = new MapboxControl((props) => (
        <LayerControlDropdown
          layersConfig={props.externalLayers}
          layersState={props.layersState}
          handleLayerChange={props.handleLayerChange}
        />
      ));

      this.map.addControl(this.layerDropdownControl, "bottom-left");
    }

    // Draggable marker for geocoding
    this.marker = new mapboxgl.Marker({
      draggable: true,
    });

    // Add Geocoder
    this.geocoder = new MapboxGeocoder({
      accessToken: mapboxAccessToken,
      mapboxgl,
      marker: false,
    });

    this.geocoder.on("result", (e) => {
      this.marker.setLngLat(e.result.center).addTo(this.map);
      this.props.handleSelectedLocation(e.result.center, e.result.place_name);
      console.log(e);
      return false;
    });

    this.map.addControl(this.geocoder);

    this.marker.on("dragend", () => {
      this.props.handleSelectedLocation(this.marker.getLngLat().toArray());
      this.geocoder.setInput("");
    });

    this.map.on("click", (e) => {
      this.marker.setLngLat(e.lngLat).addTo(this.map);
      this.props.handleSelectedLocation(e.lngLat.toArray());
      this.geocoder.setInput("");
    });

    // Remove compass.
    document.querySelector(".mapboxgl-ctrl .mapboxgl-ctrl-compass").remove();

    this.map.on("load", () => {
      this.setState({ mapLoaded: true });

      // Add external layers.
      // Layers come from the model. Each layer object must have:
      // id:            Id of the layer
      // label:         Label for display
      // type:          (vector|raster)
      // url:           Url to a tilejson or mapbox://. Use interchangeably with tiles
      // tiles:         Array of tile url. Use interchangeably with url
      // vectorLayers:  Array of source layers to show. Only in case of type vector
      externalLayers.forEach((layer) => {
        if (layer.type === "vector") {
          if (!layer.vectorLayers || !layer.vectorLayers.length) {
            // eslint-disable-next-line no-console
            return console.warn(
              `Layer [${layer.label}] has missing (vectorLayers) property.`
            );
          }
          if ((!layer.tiles || !layer.tiles.length) && !layer.url) {
            // eslint-disable-next-line no-console
            return console.warn(
              `Layer [${layer.label}] must have (url) or (tiles) property.`
            );
          }

          const sourceId = `ext-${layer.id}`;
          const options = { type: "vector" };

          if (layer.tiles) {
            options.tiles = layer.tiles;
          } else if (layer.url) {
            options.url = layer.url;
          }

          this.map.addSource(sourceId, options);
          layer.vectorLayers.forEach((vt) => {
            buildLayersForSource(sourceId, vt).forEach((l) => {
              this.map.addLayer(l, labelsAndBordersLayer);
            });
          });

          // Raster layer type.
        } else if (layer.type === "raster") {
          if (!layer.tiles || !layer.tiles.length) {
            // eslint-disable-next-line no-console
            return console.warn(
              `Layer [${layer.label}] must have (tiles) property.`
            );
          }
          const sourceId = `ext-${layer.id}`;
          this.map.addSource(sourceId, {
            type: "raster",
            tiles: layer.tiles,
          });
          this.map.addLayer(
            {
              id: `${sourceId}-tiles`,
              type: "raster",
              source: sourceId,
            },
            labelsAndBordersLayer
          );
        } else {
          // eslint-disable-next-line no-console
          console.warn(
            `Layer [${layer.label}] has unsupported type [layer.type] and won't be added.`
          );
        }
      });

      this.toggleExternalLayers();
    });
  }

  toggleExternalLayers() {
    if (!this.state.mapLoaded) return;

    const { externalLayers, layersState } = this.props;

    externalLayers.forEach((layer, lIdx) => {
      if (layer.type === "vector") {
        const layers = [
          `ext-${layer.id}-line`,
          `ext-${layer.id}-polygon`,
          `ext-${layer.id}-point`,
        ];
        const visibility = layersState[lIdx] ? "visible" : "none";
        layers.forEach((l) =>
          this.map.setLayoutProperty(l, "visibility", visibility)
        );
      } else if (layer.type === "raster") {
        const visibility = layersState[lIdx] ? "visible" : "none";
        this.map.setLayoutProperty(
          `ext-${layer.id}-tiles`,
          "visibility",
          visibility
        );
      }
    });
  }

  clearMap() {
    for (const layer of this.props.techLayers) {
      this.map.setFilter(layer.id, ["==", "id", "nothing"]);
    }
  }

  render() {
    return (
      <section className="exp-map">
        <h1 className="exp-map__title">Map</h1>
        {mapboxgl.supported() ? (
          <div ref={this.mapEl} style={{ width: "100%", height: "100%" }} />
        ) : (
          <div className="mapbox-no-webgl">
            <p>WebGL is not supported or disabled.</p>
          </div>
        )}
      </section>
    );
  }
}

if (environment !== "production") {
  Map.propTypes = {
    bounds: T.array,
    handleLayerChange: T.func,
    handleSelectedLocation: T.func,
    externalLayers: T.array,
    techLayers: T.array,
    layersState: T.array,
  };
}

export default Map;
